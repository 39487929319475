export const CLAIM_NOTIFICATIONS = {
  confirmClaim: 'Please confirm your claim of rewards in your wallet.',
  errorClaim:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  successClaim:
    'Your claim rewards are completed and your balance has been updated.',
  submittedClaim:
    'Your claim rewards have been submitted. It might take a couple of minutes to complete.',
}

export const NFT_NOTIFICATIONS = {
  confirmUnstake: 'Please confirm your unstake transaction in your wallet.',
  successUnstake:
    'You have successfully unstake and your NFTs will be updated in a few minutes.',
  errorUnstake:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  submittedUnstake:
    'You submitted your unstake transaction. This process might take a few minutes to complete.',
  confirmStake: 'Please confirm your stake transaction in your wallet.',
  successStake:
    'You have successfully stake and your NFTs will be updated in a few minutes.',
  errorStake:
    'You denied the transaction request in your wallet. Please resubmit your transaction.',
  submittedStake:
    'You submitted your stake transaction. This process might take a few minutes to complete.',
}
