import React from 'react'

const PrivacyContent = () => {
  const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL

  return (
    <>
      <h1>Privacy Policy</h1>
      <p>Last Updated: 17.01.2024</p>
      <p>We are pleased to welcome you to Casino Tycoon!</p>
      <p>
        Your privacy is of paramount importance to us. In this Privacy Policy,
        we explain how we collect, use, disclose, and safeguard your personal
        data. Please read this document carefully to fully understand how we
        process your data.
      </p>

      <div>
        <h2>1. Information Collection</h2>
        <ol>
          <li>
            <b>1.1.</b> We collect information that you provide to us when using
            our website, including your name, email address, and other data.
          </li>
          <li>
            <b>1.2.</b> We also automatically collect information related to
            your use of the website, such as your IP address, browser data, and
            device information.
          </li>
        </ol>
        <h2>2. Use of Information</h2>
        <ol>
          <li>
            <b>2.1.</b> We use the collected data to provide you with services,
            enhance the website, and interact with you.
          </li>
        </ol>
        <h2>3. Disclosure of Information</h2>
        <ol>
          <li>
            <b>3.1.</b> We do not sell, exchange, or transfer your personal data
            to third parties without your consent, except as required by law.
          </li>
        </ol>
        <h2>4. Data Protection</h2>
        <ol>
          <li>
            <b>4.1.</b> We take measures to ensure the security of your data,
            but we cannot guarantee complete security. You are also responsible
            for taking security measures when using the website.
          </li>
        </ol>
        <h2>5. Rights and Contacts</h2>
        <ol>
          <li>
            <b>5.1.</b> You have certain rights regarding your personal data. If
            you have questions or requests related to your privacy, please
            contact us at{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </li>
        </ol>
      </div>
    </>
  )
}

export default PrivacyContent
