import { useEffect } from "react"

export default function useViewportHeight() {
  useEffect(() => {
    window.addEventListener('resize', calculateWindowHeight)

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', calculateWindowHeight)
    }

    calculateWindowHeight()

    return () => {
      window.removeEventListener('resize', calculateWindowHeight)

      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          'resize',
          calculateWindowHeight
        )
      }
    }
  }, [])

  const calculateWindowHeight = () => {
    const doc = document.documentElement

    let height = window.visualViewport?.height || window.innerHeight

    doc.style.setProperty('--app-height', `${height}px`)
    document.body.style.height = `${height}px`
  }
}
