import React from 'react'
import { createUseStyles } from 'react-jss'

import { ReactComponent as OpenseaIcon } from '../../img/opensea.svg'

import { commify } from '../../utils'
import { transform } from '../../factory/bigNumber'
import { CLAIM_NOTIFICATIONS } from '../../constants/notifications'
import Skeleton from 'react-loading-skeleton'
import { useAccount, useWriteContract } from 'wagmi'

import toast from 'react-hot-toast'
import { VARIABLES } from 'src/constants/variables'
import BadgeTimer from '../BadgeTimer/BadgeTimer'
import { buttonStyle, buttonStyleTransparent } from 'src/styles/button'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import {
  BUY_NFT_LINK,
  CHAINS,
  SCAN_URL,
  STAKING_ABI,
  STAKING_CONTRACT_ADDRESS,
  TOKEN_DECIMALS,
} from 'src/constants'
import { IStaking, IUserRewards } from 'src/types/staking.interface'
import useToast from 'src/hooks/useToast'

const styles = createUseStyles({
  blur: {
    filter: VARIABLES.BLUR,
  },
  titleText: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '110%',
    letterSpacing: 0.24,
    color: VARIABLES.white,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  subTitleText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '150%',
    color: VARIABLES.grey_light,
  },
  mainText: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '110%',
    letterSpacing: 0.2,
    color: VARIABLES.white,

    '& span': {
      fontSize: 16,
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 30,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  mainBlock: {
    background: VARIABLES.card_bg,
    border: `1px solid ${VARIABLES.card_border_color}`,
    borderRadius: 8,
    padding: [27, 20, 30, 20],
    display: 'block',
    width: '100%',
    position: 'relative',
    zIndex: 5,
    boxSizing: 'border-box',
  },
  overviewInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 40,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: 10,
    marginTop: 33,
    filter: VARIABLES.BLUR,
  },
  claimButton: {
    ...buttonStyle,
    width: '100%',
    height: 48,
  },
  buyButton: {
    ...buttonStyleTransparent,
    width: '100%',
    height: 48,
    filter: 'blur(2px)',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  orbIcon: {
    width: 18,
    height: 18,
  },
  card: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: 11,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
  },
  pointer: {
    cursor: 'pointer',
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  timerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    filter: VARIABLES.BLUR,
  },
  miniButton: {
    extend: 'claimButton',
    backgroundColor: VARIABLES.green,
    height: 24,
    padding: [0, 10],
    fontSize: 12,
    letterSpacing: 0.12,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: VARIABLES.green,
    },

    '&:active': {
      backgroundColor: VARIABLES.green,
    },
  },
  [VARIABLES.tablet]: {
    card: {
      gap: 8,
    },
    buttons: {
      marginTop: 24,
    },
  },
  [VARIABLES.mobile]: {
    overviewInfoWrapper: {
      gap: 20,
      paddingRight: 0,
      flexWrap: 'wrap',
    },
    titleWrapper: {
      paddingBottom: 20,
    },
    subTitleText: {
      flexDirection: 'row',
    },
    claimButton: {},
    buttons: {
      flexDirection: 'column',
      gap: 8,
    },
    mainBlock: {
      marginBottom: 0,
      padding: [28, 16, 25, 16],
    },
    pointer: {
      cursor: 'pointer',
      display: 'flex',
      gap: 4,
      alignItems: 'center',
    },
    iconWrapper: {
      gap: 8,
    },
    miniButton: {
      display: 'none',
    },
  },
})

interface Props {
  staking: IStaking | null
  refetchStaking: () => void
}

const ClaimRewards: React.FC<Props> = ({ staking, refetchStaking }) => {
  const classes = styles()

  const { isConnected, chain } = useAccount()
  const { open: openWeb3Modal } = useWeb3Modal()
  const { notification } = useToast()

  const { writeContractAsync } = useWriteContract()

  const rewards = staking?.userRewards || []

  const claim = async () => {
    try {
      onStartClaim()

      const hash = await writeContractAsync({
        //@ts-ignore
        address: STAKING_CONTRACT_ADDRESS,
        abi: STAKING_ABI,
        functionName: 'claimRewards',
      })

      onSubmitClaim(hash)

      // await waitForTransaction({
      //   hash,
      // })

      onSuccessfulClaim(hash)
    } catch (error) {
      onErrorClaim()
    }
  }

  const onStartClaim = () => {
    notification.info({
      title: 'Info',
      text: CLAIM_NOTIFICATIONS.confirmClaim,
    })
  }

  const onSubmitClaim = (hash: string) => {
    toast.dismiss()

    notification.info({
      title: 'Info',
      text: (
        <>
          {CLAIM_NOTIFICATIONS.submittedClaim}{' '}
          <a href={`${SCAN_URL}/tx/${hash}`} target="_blank" rel="noreferrer">
            Transaction ↗
          </a>
        </>
      ),
    })
  }

  const onSuccessfulClaim = (hash: string) => {
    setTimeout(() => {
      toast.dismiss()

      notification.success({
        title: 'Success',
        text: (
          <>
            {CLAIM_NOTIFICATIONS.successClaim}{' '}
            <a href={`${SCAN_URL}/tx/${hash}`} target="_blank" rel="noreferrer">
              Transaction ↗
            </a>
          </>
        ),
      })

      refetchStaking()
    }, 5000)
  }

  const onErrorClaim = () => {
    toast.dismiss()

    notification.error({
      title: 'Error',
      text: CLAIM_NOTIFICATIONS.errorClaim,
    })
  }

  const isDisabled = () => {
    return !rewards?.find((reward: IUserRewards) => +reward.amount > 0)
  }

  return (
    <div className={classes.mainBlock}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleText}>Rewards</div>
        <div className={classes.timerContainer}>
          <BadgeTimer />
          <button
            className={classes.miniButton}
            onClick={() => claim()}
            disabled={isDisabled() || chain?.id !== CHAINS[0].id}
          >
            Claim Reward
          </button>
        </div>
      </div>
      <div className={classes.overviewInfoWrapper}>
        <div className={classes.card}>
          <div className={classes.subTitleText}>Stake NFT APR</div>
          {/* <div className={classes.row}>
            <div className={classes.mainText}>Up to 300%</div>
          </div> */}
          {false ? (
            <Skeleton
              baseColor="#4B567D"
              highlightColor="#5B6796"
              width="100px"
              height="20px"
              count={1}
              borderRadius="12px"
            />
          ) : (
            <div className={classes.row}>
              <div className={classes.mainText}>
                {/* 0% */}
                up to {transform((staking?.APR || 0).toString(), 2)}%
              </div>
            </div>
          )}
        </div>
        {isConnected
          ? rewards
            ? rewards?.map((el: IUserRewards, index: number) => {
                return (
                  <div className={`${classes.card}`} key={index}>
                    <div className={classes.subTitleText}>
                      {el?.symbol} Rewards {index + 1 > 1 ? index + 1 : null}
                    </div>
                    <div className={classes.iconWrapper}>
                      <img className={classes.orbIcon} src={el.image} alt="" />
                      <div
                        className={classes.mainText}
                        dangerouslySetInnerHTML={{
                          __html: commify(transform(el.amount, TOKEN_DECIMALS)),
                        }}
                      ></div>
                    </div>
                  </div>
                )
              })
            : [1, 2, 3].map((el) => {
                return (
                  <div key={el} className={classes.card}>
                    <Skeleton
                      baseColor="#4B567D"
                      highlightColor="#5B6796"
                      width="100px"
                      height="20px"
                      count={1}
                      borderRadius="12px"
                    />
                    <Skeleton
                      baseColor="#4B567D"
                      highlightColor="#5B6796"
                      width="100px"
                      height="20px"
                      count={1}
                      borderRadius="12px"
                    />
                  </div>
                )
              })
          : null}
      </div>
      <div className={classes.buttons}>
        {isConnected ? (
          <button
            onClick={() => claim()}
            disabled={isDisabled() || chain?.id !== CHAINS[0].id}
            className={classes.claimButton}
          >
            <div>Claim All Rewards</div>
          </button>
        ) : (
          <button
            className={classes.claimButton}
            onClick={() => openWeb3Modal()}
          >
            Connect Wallet
          </button>
        )}
        <a
          className={classes.buyButton}
          href={BUY_NFT_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Buy on OpenSea <OpenseaIcon />
        </a>
      </div>
    </div>
  )
}
export default ClaimRewards
