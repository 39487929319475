import React from 'react'

const TermsContent = () => {
  const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL

  return (
    <>
      <h1>Terms of Use</h1>
      <p>Welcome to Casino Tycoon!</p>
      <p>
        Please carefully read the following Terms of Use before using our
        website. Visiting and using our website implies your agreement to these
        Terms of Use, and you are obligated to comply with them. If you do not
        agree with any of these terms, please do not use our website.
      </p>

      <div>
        <h2>1. User Agreement</h2>
        <ol>
          <li>
            <b>1.1.</b> Your use of the website implies adherence to this User
            Agreement and all applicable laws and regulations.
          </li>
        </ol>
        <h2>2. Modification of Terms of Use</h2>
        <ol>
          <li>
            <b>2.1. </b> We reserve the right to change or update the Terms of
            Use at any time without prior notice. Any changes become effective
            upon their publication on the website. Please regularly check the
            Terms of Use to stay informed about the latest updates.
          </li>
        </ol>
        <h2>3. Privacy</h2>
        <ol>
          <li>
            <b>3.1.</b> We respect your privacy. Please review our Privacy
            Policy to understand how we collect, store, and process your
            personal data.
          </li>
        </ol>
        <h2>4. Intellectual Property</h2>
        <ol>
          <li>
            <b>4.1.</b> All content on the website, including text, images,
            graphics, logos, videos, and audio, is the intellectual property of
            [Your Website Name] or our partners. All rights are reserved.
          </li>
        </ol>
        <h2>5. Limitation of Liability</h2>
        <ol>
          <li>
            <b>5.1.</b> We make every effort to ensure the accuracy and
            timeliness of information on the website. However, we are not liable
            for any losses or damages resulting from the use of our website.
          </li>
        </ol>
        <h2>6. Links to Third-Party Resources</h2>
        <ol>
          <li>
            <b>6.1.</b> Our website may contain links to third-party websites
            that are not under our control. We are not responsible for the
            content or actions of such third-party resources.
          </li>
        </ol>

        <h2>7. Contact</h2>
        <ol>
          <li>
            <b>7.1.</b> If you have questions or comments regarding our Terms of
            Use, please contact us at{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
          </li>
        </ol>
      </div>
    </>
  )
}

export default TermsContent
