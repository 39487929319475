import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'

import { ReactComponent as MinusIcon } from '../../img/minus_circle.svg'
import { ReactComponent as PlusIcon } from '../../img/plus_circle.svg'

const styles = createUseStyles({
  wrapper: {
    padding: [0, 8],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: [0, 'auto'],
    width: 158,
  },
  disable: {
    pointerEvents: 'none',
    '& $button': {
      opacity: 0.5,
    },
    '& $value': {
      opacity: 0.5,
    },
  },
  button: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#CBCBF8',
    backgroundColor: 'transparent',
    padding: 0,
    cursor: 'pointer',
    transition: 'all 0.2s ease',

    '&:hover': {
      color: VARIABLES.white,
    },

    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },
  value: {
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0.32,
    lineHeight: '160%',
    textAlign: 'center',
    color: VARIABLES.white,
    userSelect: 'none',
  },
})

interface IProps {
  max: number
  min?: number
  onChange: (value: number) => void
  disable: boolean
}

const Counter: React.FC<IProps> = ({
  max = 10,
  min = 1,
  onChange,
  disable = false,
}) => {
  const classes = styles()
  const [count, setCount] = useState(min)


  useEffect(() => {
    setCount(min)
  }, [max])

  const decrement = () => {
    const newCount = count - 1

    if (newCount < 1) {
      return
    }

    setCount(newCount)
    onChange(newCount)
  }
  const increment = () => {
    const newCount = count + 1

    if (newCount > max) {
      return
    }

    setCount(newCount)
    onChange(newCount)
  }

  return (
    <div className={`${classes.wrapper} ${disable ? classes.disable : ''}`}>
      <button
        className={classes.button}
        onClick={decrement}
        disabled={count <= 1}
      >
        <MinusIcon />
      </button>
      <div className={classes.value}>{disable ? 0 : count}</div>
      <button
        className={classes.button}
        disabled={count === max}
        onClick={increment}
      >
        <PlusIcon />
      </button>
    </div>
  )
}

export default Counter
