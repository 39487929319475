import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

import { VARIABLES } from 'src/constants/variables'

import { ReactComponent as BriefcaseIcon } from '../../img/briefcase.svg'
import { ReactComponent as MedalIcon } from '../../img/medal.svg'
import { ReactComponent as ConvertIcon } from '../../img/convert.svg'
import { ReactComponent as TradingIcon } from '../../img/buy-crypto.svg'

const styles = createUseStyles({
  wrapper: {},
  title: {
    fontSize: 42,
    fontWeight: 500,
    color: VARIABLES.white,
    lineHeight: '120%',
    textAlign: 'center',
    margin: 0,
    letterSpacing: 2.24,
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    color: VARIABLES.text_grey,
    lineHeight: '150%',
    textAlign: 'center',
    marginTop: 16,
  },
  grid: {
    display: 'flex',
    gap: 20,
    marginTop: 60,
  },
  card: {
    backgroundColor: VARIABLES.card_bg,
    border: `1px solid ${VARIABLES.card_border_color}`,
    padding: [30, 17, 30, 20],
    flexBasis: 325,
  },
  icon: {
    width: 48,
    height: 48,
    color: VARIABLES.white,
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '30px',
    color: VARIABLES.white,
    marginTop: 20,
    marginBottom: 0,
  },
  cardText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    color: VARIABLES.text_grey,
    marginTop: 12,
    marginBottom: 0,
  },
  [VARIABLES.tablet]: {
    wrapper: {},
    grid: {
      flexWrap: 'wrap',
      marginTop: 40,
      gap: 10,
    },
    card: {
      width: '100%',
      flexBasis: 'unset',
    },
    title: {
      textAlign: 'left',
    },
    text: {
      textAlign: 'left',
    },
  },
  [VARIABLES.mobile]: {
    wrapper: {
      marginTop: 20,
    },
    title: {
      fontSize: 24,
      letterSpacing: 0.08,
    },
  },
})

interface ICardProps {
  title: string
  text: string
  icon: ReactNode
}

const AdvantagesCard: React.FC<ICardProps> = ({ title, text, icon }) => {
  const classes = styles()

  return (
    <div className={classes.card}>
      {icon}
      <p className={classes.cardTitle}>{title}</p>
      <p className={classes.cardText}>{text}</p>
    </div>
  )
}

const Advantages = () => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.title}>Casino TYCOON Uniqueness</h2>
      <p className={classes.text}>
        Explore what makes NFTs so exclusive and special
      </p>
      <div className={classes.grid}>
        <AdvantagesCard
          icon={<BriefcaseIcon className={classes.icon} />}
          title="Real ownership"
          text="Each NFT represent real stake in various digital casino making you casino co-owner. Literally."
        />
        <AdvantagesCard
          icon={<MedalIcon className={classes.icon} />}
          title="Casino profit = your profit"
          text="Owning staking NFTs gives you an opportunity to earn rewards each time slots are being played."
        />
        <AdvantagesCard
          icon={<ConvertIcon className={classes.icon} />}
          title="Cryptocurrency variety"
          text="NFTs have limited amount and different grades. Cath up to be to an owner!"
        />
        <AdvantagesCard
          icon={<TradingIcon className={classes.icon} />}
          title="Trading"
          text="You can always buy and sell NFTs on market-place like OpenSea. Collect exactly your portfolio!"
        />
      </div>
    </div>
  )
}

export default Advantages
