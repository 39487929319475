import React, { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'
import { buttonStyle, buttonStyleTransparent } from 'src/styles/button'

import { ReactComponent as ImageIcon } from '../../img/imgIcon.svg'
import { ReactComponent as ETHIcon } from '../../img/chains/ethereum.svg'

import { INFT, INFTMarketplace } from 'src/types/nft.interface'
import { getIPFSImage } from 'src/utils'
import LoaderIcon from '../LoaderIcon/LoaderIcon'
import LazyLoad from 'react-lazyload'

const styles = createUseStyles({
  mainWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 10,
    padding: 16,
    background: 'rgba(255, 255, 255, 0.02)',
    border: `1px solid ${VARIABLES.card_border_color}`,
    borderRadius: 8,
    minWidth: 325,
    flexBasis: 325,
    width: '100%',
    gap: 16,
    height: '100%',
    boxSizing: 'border-box',
    aspectRatio: 325 / 489,
  },
  imgContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: 260,
    maxHeight: '50%',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${VARIABLES.card_border_color}`,
    flexGrow: 1,
  },
  nftImg: {
    objectFit: 'contain',
    width: '100%',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  nftIndex: {
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: VARIABLES.bg,
    color: VARIABLES.white,
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 'normal',
    height: 24,
    padding: [0, 10],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '110%',
    color: VARIABLES.white,
    whiteSpace: 'nowrap',
    letterSpacing: '0.18',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '148%',
    color: VARIABLES.text_grey,
    minHeight: 16,
  },
  textGreen: {
    extend: 'text',
    color: VARIABLES.green,
  },
  priceWrapper: {
    extend: 'row',
    marginTop: -3,
    marginBottom: 3,
  },
  priceLabel: {
    extend: 'text',
    fontWeight: 500,
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  price: {
    extend: 'text',
    fontWeight: 700,
    letterSpacing: 0.16,
    color: VARIABLES.white,
    margin: 0,
  },
  priceSale: {
    extend: 'price',
    color: VARIABLES.pink,
    textDecoration: 'line-through',
    fontWeight: 500,
  },
  stakeButton: {
    ...buttonStyle,
    height: 48,
    padding: [0, 24],
    width: '100%',
    minHeight: 48,
    marginTop: 'auto',
  },
  unStakeButton: {
    ...buttonStyleTransparent,
    height: 48,
    padding: [0, 24],
    width: '100%',
    minHeight: 48,
    marginTop: 'auto',
  },
  placeholderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    color: VARIABLES.text_grey,
    gap: 8,
  },
  placeholderIcon: {
    width: 24,
    height: 24,
  },
  placeholderText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    margin: 0,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: VARIABLES.primary,
  },
  icon: {
    width: 19,
    height: 19,
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: VARIABLES.bg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media (max-width: 1439px)': {
    mainWrapper: {
      aspectRatio: 373 / 569,
    },
  },

  '@media (max-width: 1239px)': {
    mainWrapper: {
      aspectRatio: 461 / 649,
    },
  },
  [VARIABLES.tablet]: {
    mainWrapper: {
      minWidth: 325,
      flexBasis: 325,
      width: 325,
      aspectRatio: 325 / 517,
    },
  },
  [VARIABLES.mobile]: {
    mainWrapper: {
      width: 280,
      minWidth: 280,
      flexBasis: 280,
      minHeight: 517,
    },
    imgContainer: {
      minHeight: 228,
    },
  },
})

interface IProps {
  forMarketplace: boolean
  data: 'forMarketplace' extends true ? INFTMarketplace : INFT
  disableStake?: boolean
  stake?: (id: number) => void
  unstake?: (id: number) => void
  isWrongNetwork: boolean
  className?: string
  onBuy?: () => void
  isDiscount?: boolean
  discount?: number
}

const NftCard: React.FC<IProps> = ({
  data,
  disableStake,
  stake,
  unstake,
  isWrongNetwork,
  className,
  forMarketplace,
  onBuy,
  isDiscount = false,
  discount = 0,
}) => {
  const classes = styles()

  const [isImgError, setIsImgError] = useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)

  const videoUrl = useMemo(() => {
    return getIPFSImage(data?.ipfsImage)
  }, [data])

  const videoRef = React.createRef<HTMLVideoElement>()

  const handleImgError = () => {
    setIsImgError(true)
  }

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true)
  }

  return (
    <div className={`${classes.mainWrapper} ${className || ''}`}>
      <div className={classes.imgContainer}>
        {!isImgError ? (
          <>
            {!isVideoLoaded && (
              <div className={classes.videoOverlay}>
                <LoaderIcon />
              </div>
            )}
            <div className={classes.imgOverlay}></div>
            <LazyLoad height="200">
              <video
                src={videoUrl}
                ref={videoRef}
                className={classes.nftImg}
                autoPlay={true}
                loop
                muted={true}
                playsInline
                controls={false}
                preload="none"
                onError={handleImgError}
                onLoadedData={handleVideoLoaded}
              ></video>
            </LazyLoad>
          </>
        ) : (
          <div className={classes.placeholderWrapper}>
            <ImageIcon className={classes.placeholderIcon} />
            <p className={classes.placeholderText}>Can’t Download Image</p>
          </div>
        )}
      </div>
      <div className={classes.header}>
        <div className={classes.title}>{data?.name}</div>
      </div>
      <div className={classes.content}>
        {forMarketplace && (
          <div className={classes.row}>
            <div className={classes.text}>Available</div>
            {/* @ts-ignore */}
            <div className={classes.text}>{data.available}</div>
          </div>
        )}

        <div className={classes.row}>
          <div className={classes.text}>Token ID</div>
          <div className={classes.text}>{data.tokenId}</div>
        </div>

        {!forMarketplace && (
          <>
            <div className={classes.row}>
              <div className={classes.text}>PNL 1</div>
              <div className={classes.textGreen}>{data?.pnl1 || '-'}</div>
            </div>
            <div className={classes.row}>
              <div className={classes.text}>PNL 2</div>
              <div className={classes.textGreen}>{data?.pnl2 || '-'}</div>
            </div>
            <div className={classes.row}>
              <div className={classes.text}>PNL 3</div>
              <div className={classes.textGreen}>{data?.pnl3 || '-'}</div>
            </div>
            <div className={classes.row}>
              <div className={classes.text}>Rewards</div>
              <div className={classes.textGreen}>{data?.total || '0'}</div>
            </div>
          </>
        )}
      </div>
      <div className={classes.divider}></div>
      {forMarketplace && (
        <div className={classes.priceWrapper}>
          <div className={classes.priceLabel}>Price</div>

          <div className={classes.priceContainer}>
            <ETHIcon className={classes.icon} />
            {isDiscount && <p className={classes.priceSale}>{data.price}</p>}
            <p className={classes.price}>
              {isDiscount
                ? Number(data.price * ((100 - discount) / 100))
                : data.price}
            </p>
          </div>
        </div>
      )}

      {forMarketplace ? (
        // <button disabled={true} className={classes.stakeButton}>
        //   Coming Soon
        // </button>
        <button
          onClick={onBuy}
          disabled={!data.available}
          className={classes.stakeButton}
        >
          {!!data.available ? 'Buy Now' : 'Sold Out'}
        </button>
      ) : (
        <>
          {data?.isLocked ? (
            <button
              disabled={disableStake || isWrongNetwork}
              onClick={() => {
                unstake && unstake(data?.tokenId)
              }}
              className={classes.unStakeButton}
            >
              Unstake
            </button>
          ) : (
            <button
              disabled={disableStake || isWrongNetwork}
              onClick={() => {
                stake && stake(data?.tokenId)
              }}
              className={classes.stakeButton}
            >
              Stake
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default NftCard
