import React from 'react'
import { createUseStyles } from 'react-jss'

import { ReactComponent as Logo } from '../../img/logo.svg'
import { ReactComponent as TelegramIcon } from '../../img/telegram.svg'
import { ReactComponent as TwitterIcon } from '../../img/x.svg'
import { VARIABLES } from 'src/constants/variables'

import { Link } from 'react-router-dom'

const styles = createUseStyles({
  footer: {
    position: 'relative',
    zIndex: 4,
    maxWidth: VARIABLES.desktop_container,
    margin: '0 auto',
    padding: [54, 40],
  },
  logo: {
    cursor: 'pointer',
  },
  footerWrapper: {
    position: 'relative',
    width: '100%',
    backgroundColor: VARIABLES.purple,
    marginTop: 'auto',
    zIndex: 0,
    height: '100%',
    boxSizing: 'border-box',
  },
  topContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    cursor: 'pointer',
    width: 20,
    height: 20,
    color: VARIABLES.white,
    transition: VARIABLES.element_transition,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      color: VARIABLES.text_grey,
    },
  },
  iconImg: {
    width: 20,
    height: 20,
  },
  iconImgTwitter: {
    width: 16,
    height: 16,
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 20,
  },
  socialLinks: {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
  },
  copyright: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '150%',
    color: VARIABLES.text_grey,
  },
  docsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 13,
  },
  subTitleText: {
    color: VARIABLES.white,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: '0.2px',
  },
  rightContent: {
    display: 'flex',
    gap: 60,
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  link: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '150%',
    color: VARIABLES.text_grey,
    textDecoration: 'none',
    fontFamily: VARIABLES.fontFamily,
    transition: VARIABLES.element_transition,

    '&:hover': {
      color: VARIABLES.white,
    },
  },
  [VARIABLES.tablet]: {
    footer: {
      padding: [60, 40],
    },
    logoWrapper: {},
    rightContent: {
      gap: 48,
    },
  },
  [VARIABLES.mobile]: {
    footer: {
      padding: [57, 20, 57, 24],
    },
    topContentWrapper: {
      flexDirection: 'column',
      gap: 34,
    },
    subTitleText: {
      fontSize: 18,
    },
    rightContent: {
      gap: 33,
    },
    docsWrapper: {
      gap: 14,
    },
    link: {
      fontSize: 14,
    },
    icon: {
      height: 20,
      width: 20,
    },
    iconImg: {
      height: 20,
      width: 20,
    },
    socialLinks: {
      gap: 20,
    },
  },
})

const Footer: React.FC = () => {
  const classes = styles()

  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.footer}>
        <div className={classes.topContentWrapper}>
          <div className={classes.logoWrapper}>
            <Logo
              className={classes.logo}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }
            />
            <div className={classes.copyright}>
              © 2024 Casino TYCOON. All Rights Reserved.
            </div>
          </div>
          <div className={classes.rightContent}>
            <div className={classes.docsWrapper}>
              <div className={classes.subTitleText}>Documents</div>
              <div className={classes.links}>
                <Link to="/privacy-policy" className={classes.link}>
                  Privacy Policy
                </Link>
                <Link to="/terms-of-service" className={classes.link}>
                  Terms of service
                </Link>
              </div>
            </div>
            <div className={classes.docsWrapper}>
              <div className={classes.subTitleText}>Join the community</div>
              <div className={classes.socialLinks}>
                {/* <a
                  href="#"
                  target="_blank"
                  className={classes.icon}
                  rel="noreferrer"
                >
                  <DiscordIcon className={classes.iconImg} />
                </a> */}
                <a
                  href="https://t.me/Casino_TYCOON"
                  target="_blank"
                  className={classes.icon}
                  rel="noreferrer"
                >
                  <TelegramIcon className={classes.iconImg} />
                </a>
                <a
                  href="https://twitter.com/casino_tycoon"
                  target="_blank"
                  className={classes.icon}
                  rel="noreferrer"
                >
                  <TwitterIcon className={classes.iconImgTwitter} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
