import React from 'react'
import { createUseStyles } from 'react-jss'
import ReactPaginate from 'react-paginate'
import { VARIABLES } from 'src/constants/variables'

import { ReactComponent as ChevronIcon } from '../../img/chevron.svg'

const styles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    paddingLeft: 0,
  },
  page: {
    color: VARIABLES.white,
    textDecoration: 'none',
    listStyle: 'none',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    transition: VARIABLES.element_transition,
    userSelect: 'none',
    fontSize: 14,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  },
  pageLink: {
    color: VARIABLES.white,
    textDecoration: 'none',
    listStyle: 'none',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    transition: VARIABLES.element_transition,
    userSelect: 'none',
    fontSize: 14,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  },
  active: {
    extend: 'page',
    backgroundColor: '#5C519E',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#5C519E',
    },
  },
  prev: {
    listStyle: 'none',
    extend: 'page',
  },
  next: {
    listStyle: 'none',
    extend: 'page',
  },
  break: {
    extend: 'page',
    listStyle: 'none',
  },
  disabled: {
    extend: 'page',
    color: '#68688B',
    cursor: 'default',
    pointerEvents: 'none',
  },
  prevArrow: {
    width: 16,
    height: 16,
    transform: 'rotate(90deg)',
  },
  nextArrow: {
    width: 16,
    height: 16,
    transform: 'rotate(-90deg)',
  },
})

interface IProps {
  onPageChange: (data: { selected: number }) => void
  pageCount: number
  initialPage?: number
  forcePage?: number
}

const Pagination: React.FC<IProps> = ({
  onPageChange,
  initialPage,
  pageCount,
  forcePage,
}) => {
  const classes = styles()

  return (
    <div>
      <ReactPaginate
        pageCount={pageCount}
        containerClassName={classes.container}
        pageClassName={classes.page}
        pageLinkClassName={classes.pageLink}
        activeClassName={classes.active}
        previousClassName={classes.prev}
        nextClassName={classes.next}
        breakClassName={classes.break}
        disabledClassName={classes.disabled}
        disabledLinkClassName={classes.disabled}
        previousLinkClassName={classes.pageLink}
        nextLinkClassName={classes.pageLink}
        previousLabel={<ChevronIcon className={classes.prevArrow} />}
        nextLabel={<ChevronIcon className={classes.nextArrow} />}
        initialPage={initialPage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={onPageChange}
        forcePage={forcePage}
      />
    </div>
  )
}

export default Pagination
