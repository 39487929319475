import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'

import { ReactComponent as TimerIcon } from '../../img/timer.svg'
import { useTimer } from 'react-timer-hook'
import moment from 'moment'

const styles = createUseStyles({
  wrapper: {
    height: 24,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 4,
    color: VARIABLES.blue_secondary,
    letterSpacing: 0.12,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 'normal',
    width: 123,
    whiteSpace: 'nowrap',
    padding: [0, 10],
    boxSizing: 'border-box',
  },
  textContainer: {
    width: 90,
  },
  iconContainer: {
    width: 12,
    height: 12,
  },
  icon: {
    width: 12,
    height: 12,
  },
})

const BadgeTimer = () => {
  const classes = styles()

  const getExpiryTimestamp = () => {
    return moment().endOf('month').toDate()
  }

  const expiryTimestamp = getExpiryTimestamp()

  const restartTime = () => {
    const time = getExpiryTimestamp()

    setTimeout(() => {
      restart(time)
    }, 1000)
  }

  const { seconds, minutes, hours, restart, days } = useTimer({
    expiryTimestamp,
    onExpire: () => restartTime(),
  })

  const formatTime = (time: number) => {
    return String(time).padStart(2, '0')
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.iconContainer}>
        <TimerIcon className={classes.icon} />
      </div>
      <span className={classes.textContainer}>
        {`${days}d ${formatTime(hours)}h ${formatTime(minutes)}m ${formatTime(
          seconds
        )}s`}
      </span>
    </div>
  )
}

export default BadgeTimer
