import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'
import LoaderIcon from '../LoaderIcon/LoaderIcon'

const styles = createUseStyles({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(30, 20, 59, 0.90)',
    zIndex: 99999,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 10,
  },
  iconContainer: {
    height: 50,
  },
  icon: {
    width: 32,
    height: 32,
    animation: 'rotate 2s infinite linear',
  },
  text: {
    margin: 0,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: 0.18,
    color: VARIABLES.white,
  },

  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
})

const Loader = () => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.iconContainer}>
          <LoaderIcon />
        </div>
        {/* <p className={classes.text}>Loading...</p> */}
      </div>
    </div>
  )
}
export default Loader
