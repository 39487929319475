import { CHAINS_ICONS } from './icons'
//@ts-ignore
import nftContractAbi from '../contracts/nft.abi'
//@ts-ignore
import stakingContractAbi from '../contracts/staking.abi'
//@ts-ignore
import nftSaleContractAbi from '../contracts/nftSale.abi'
//@ts-ignore
import usdtTokenAbi from '../contracts/usdt.abi'
import { sepolia, mainnet } from 'wagmi/chains'

const BUY_NFT_LINK = 'https://opensea.io'

const IS_MAINNET = process.env.REACT_APP_X_IS_MAINNET === 'true'
const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_X_NFT_CONTRACT_ADDRESS
const STAKING_CONTRACT_ADDRESS =
  process.env.REACT_APP_X_STAKING_CONTRACT_ADDRESS
const NFT_SALE_CONTRACT_ADDRESS =
  process.env.REACT_APP_NFT_SALE_CONTRACT_ADDRESS
const USDT_TOKEN_ADDRESS = process.env.REACT_APP_USDT_TOKEN_ADDRESS
const NFT_SALE_ABI = nftSaleContractAbi
const NFT_CONTRACT_ABI = nftContractAbi
const STAKING_ABI = stakingContractAbi
const CHAINS = [IS_MAINNET ? mainnet : sepolia]
const USDT_TOKEN_ABI = usdtTokenAbi
const MARKETPLACE_DISCOUNT = process.env.REACT_APP_MARKETPLACE_DISCOUNT

const SCAN_URL = IS_MAINNET
  ? 'https://etherscan.io'
  : 'https://sepolia.etherscan.io'

const INFINITY =
  '115792089237316195423570985008687907853269984665640564039457.584007913129639935'

const TOKEN_DECIMALS = 18

export {
  CHAINS_ICONS,
  BUY_NFT_LINK,
  NFT_CONTRACT_ADDRESS,
  STAKING_CONTRACT_ADDRESS,
  NFT_CONTRACT_ABI,
  STAKING_ABI,
  CHAINS,
  SCAN_URL,
  INFINITY,
  NFT_SALE_ABI,
  NFT_SALE_CONTRACT_ADDRESS,
  USDT_TOKEN_ADDRESS,
  USDT_TOKEN_ABI,
  MARKETPLACE_DISCOUNT,
  TOKEN_DECIMALS,
}
