import { VARIABLES } from 'src/constants/variables'

export const buttonStyle = {
  background: VARIABLES.primary,
  color: VARIABLES.white,
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  cursor: 'pointer',
  position: 'relative',
  transition: 'all 0.2s ease',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: 'normal',
  letterSpacing: 0.16,
  fontFamily: VARIABLES.fontFamily,
  textDecoration: 'none',
  userSelect: 'none',

  '@media(hover: hover)': {
    '&:hover': {
      background: VARIABLES.primary_hover,
    },
  },

  '&:active': {
    background: VARIABLES.primary_active,
  },

  '&:disabled': {
    backgroundColor: '#363050',
    color: VARIABLES.grey_lighter,
    cursor: 'not-allowed',

    '@media(hover: hover)': {
      '&:hover': {
        background: '#363050',
      },
    },
  },
}

export const buttonStyleTransparent = {
  ...buttonStyle,
  backgroundColor: 'transparent',
  color: VARIABLES.white,
  border: `1px solid ${VARIABLES.card_border_color}`,

  '@media(hover: hover)': {
    '&:hover': {
      color: VARIABLES.bg,
      backgroundColor: VARIABLES.white,
    },
  },

  '&:active': {
    background: 'transparent',
    color: VARIABLES.white,
  },

  '&:disabled': {
    color: 'rgba(235, 235, 235, 0.80)',
    background: 'transparent',
    cursor: 'not-allowed',
  },
}

export const buttonStyleWhite = {
  ...buttonStyle,
  backgroundColor: VARIABLES.white,
  color: VARIABLES.bg,

  '@media(hover: hover)': {
    '&:hover': {
      color: VARIABLES.bg,
      backgroundColor: VARIABLES.text_grey,
    },
  },

  '&:active': {
    background: VARIABLES.text_grey,
    color: VARIABLES.bg,
  },
}
