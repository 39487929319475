import React, { useState } from 'react'

import { ReactComponent as CopyIcon } from '../../img/copy.svg'
import { ReactComponent as CopyIconFilled } from '../../img/copy_filled.svg'
import { ReactComponent as LogoutIcon } from '../../img/logout.svg'
import { ReactComponent as WalletConnectIcon } from '../../img/WalletConnect.svg'
import { ReactComponent as SpinnerIcon } from '../../img/spinner.svg'

//@ts-ignore
import avatarImg from '../../img/avatar.png'

import { createUseStyles } from 'react-jss'
import useComponentVisible from '../../hooks/useComponentVisible'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'

import toast from 'react-hot-toast'

import { useWeb3Modal } from '@web3modal/wagmi/react'

import { useAccount, useDisconnect } from 'wagmi'
import { truncate } from 'src/utils'
import { VARIABLES } from 'src/constants/variables'
import { CHAINS_ICONS } from 'src/constants'
import Badge from '../Badge/Badge'
import ToastBody from '../ToastBody/ToastBody'
import { buttonStyle } from 'src/styles/button'

const styles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  walletButton: {
    ...buttonStyle,
    height: 36,
    padding: [0, 12],
    userSelect: 'none',
  },
  walletIcon: {
    height: 16,
    width: 16,
    color: 'white',
  },
  walletText: {
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: 'normal',
    color: VARIABLES.white,
  },
  walletNotConnectedText: {
    fontSize: 14,
    extend: 'walletText',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: 0.14,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  connectedModal: {
    width: 270,
    background: VARIABLES.purple,
    position: 'absolute',
    border: `1px solid ${VARIABLES.card_border_color}`,
    borderRadius: 8,
    top: 'calc(100% + 8px)',
    padding: 4,
    right: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    margin: 0,
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    background: 'white',
    position: 'relative',
  },
  avatarIcon: {
    position: 'absolute',
    bottom: 0,
    right: -1,
    width: 13,
    height: 13,
  },
  nameContainer: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  },
  connectedModalRef: {
    display: 'contents',
  },
  modalTextValue: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: '150%',
  },
  chainIcon: {},
  chainContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  modalLabel: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '150%',
    color: VARIABLES.white,
  },
  copyIcon: {
    cursor: 'pointer',
  },
  copyIconFilled: {
    extend: 'copyIcon',
    display: 'none',
  },
  modalItem: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    padding: [0, 8],
    borderRadius: 8,
    height: 48,
    transition: 'all 0.2s ease',
    color: VARIABLES.white,
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover': {
      background: VARIABLES.card_bg,
      color: VARIABLES.text_grey,

      '& $copyIcon': {
        display: 'none',
      },
      '& $copyIconFilled': {
        display: 'block',
      },
    },
  },
  modalItemDisabled: {
    extend: 'modalItem',
    pointerEvents: 'none',
    cursor: 'default',
    userSelect: 'none',
  },
  disconnectButton: {
    extend: 'modalItem',
    color: VARIABLES.text_grey,
    background: 'transparent',
    cursor: 'pointer',
    userSelect: 'none',
  },
  avatarImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  disconnectIcon: {
    width: 16,
    height: 16,
  },

  loader: {
    animation: 'rotate 2s infinite linear',
    transform: 'translateZ(0)',
    width: 16,
    height: 16,
  },
  onlyDesktop: {},
  [VARIABLES.mobile]: {
    walletIcon: {
      display: 'none',
    },
    onlyDesktop: {
      display: 'none',
    },
  },

  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
})

interface Props {}

const WalletButton: React.FC<Props> = () => {
  const { address, isConnected, isConnecting, chain } = useAccount()
  const { disconnect } = useDisconnect()
  const [isCopied, setIsCopied] = useState(false)
  const { open: openWeb3Modal } = useWeb3Modal()

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const classes = styles()

  const handleOpen = () => {
    if (isConnected) {
      setIsComponentVisible((prev) => !prev)
    } else {
      openWeb3Modal()
    }
  }

  const handleDisconnect = async () => {
    await disconnect()
    setIsComponentVisible(false)
  }

  return isConnected ? (
    <div
      ref={ref}
      className={classes.wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.connectedModalRef}>
        {isComponentVisible && isConnected && ( 
          <>
            <div className={classes.connectedModal}>
              <div
                className={classes.modalItem}
                onClick={() => {
                  if (!address) return
                  navigator.clipboard.writeText(address as any)
                  setIsCopied(true)

                  if (isCopied) {
                    toast.custom((t) => (
                      <ToastBody
                        text="Wallet address is copied"
                        title="Success"
                        type="success"
                        id={t.id}
                      />
                    ))
                  }
                }}
              >
                <div className={classes.nameContainer}>
                  <div className={classes.avatar}>
                    <img
                      className={classes.avatarImage}
                      src={avatarImg}
                      alt=""
                    />
                    <WalletConnectIcon className={classes.avatarIcon} />
                  </div>
                  <span className={classes.modalTextValue}>
                    {truncate(address, 11) || (
                      <Skeleton
                        baseColor="#4B567D"
                        highlightColor="#5B6796"
                        width="100%"
                        count={1}
                      />
                    )}
                  </span>
                </div>
                {isCopied ? (
                  <Badge value="Copied!" />
                ) : (
                  <>
                    <CopyIcon className={classes.copyIcon} />
                    <CopyIconFilled className={classes.copyIconFilled} />
                  </>
                )}
              </div>
              <div
                className={`${classes.modalItem} ${classes.modalItemDisabled}`}
              >
                <span className={classes.modalLabel}>Network Type</span>
                <div className={classes.chainContainer}>
                  <img
                    className={classes.chainIcon}
                    src={CHAINS_ICONS[chain?.id || -1]}
                    alt=""
                  />
                  <span className={classes.modalTextValue}>{chain?.name}</span>
                </div>
              </div>
              <button
                className={classes.disconnectButton}
                type="button"
                onClick={() => handleDisconnect()}
              >
                Disconnect
                <LogoutIcon className={classes.disconnectIcon} />
              </button>
            </div>
          </>
        )}
      </div>
      <button
        className={classes.walletButton}
        type="button"
        onClick={handleOpen}
      >
        <WalletConnectIcon className={classes.walletIcon} />
        <span className={classes.walletText}>
          {truncate(address, 11) || (
            <Skeleton
              baseColor="#4B567D"
              highlightColor="#5B6796"
              width="100%"
              count={1}
            />
          )}
        </span>
      </button>
    </div>
  ) : (
    <div onClick={(e) => e.stopPropagation()}>
      <button
        className={classes.walletButton}
        type="button"
        onClick={handleOpen}
      >
        <span className={classes.walletNotConnectedText}>
          {isConnecting ? (
            <>
              <SpinnerIcon className={classes.loader} /> Connection...{' '}
            </>
          ) : isConnected ? (
            truncate(address, 11) ? (
              !isConnected && isConnecting
            ) : (
              'Connecting...'
            )
          ) : (
            <>
              {/* Launching soon */}
              Connect<span className={classes.onlyDesktop}> Wallet</span>
            </>
          )}
        </span>
      </button>
    </div>
  )
}

export default WalletButton
