import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { INFTMarketplace } from 'src/types/nft.interface'

export interface nftsState {
  nft: INFTMarketplace[]
}

const initialState: nftsState = {
  nft: [],
}

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    setMarketplaceNfts: (state, action: PayloadAction<INFTMarketplace[]>) => {
      state.nft = action.payload
    },
  },
})

export const { setMarketplaceNfts } = marketplaceSlice.actions

export default marketplaceSlice.reducer
