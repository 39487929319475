import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Header from 'src/components/Header/Header'
import Footer from '../components/Footer/Footer'
import { VARIABLES } from 'src/constants/variables'

import { useAccount } from 'wagmi'
import WrongNetworkModal from 'src/components/WrongNetworkModal/WrongNetworkModal'
import DocumentContent from 'src/components/DocumentContent/DocumentContent'
import TermsContent from 'src/components/DocumentContent/TermsContent'
import { setLoading } from '../redux/loading'
import { useDispatch } from 'react-redux'
import { CHAINS } from 'src/constants'
import SupportButton from 'src/components/SupportButton/SupportButton'
import bgImage from '../img/bg.png'

const styles = createUseStyles({
  bgColor: {
    height: '100%',
  },
  allContentWisible: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  wrapperHeader: {
    width: '100vw',
    position: 'fixed',
    backgroundColor: 'transparent',
    zIndex: 1100,
  },
  wrapperSmallHeader: {
    extend: 'wrapperHeader',
    backdropFilter: 'blur(30px)',
  },
  wrapper: {
    margin: '0 auto',
    maxWidth: VARIABLES.desktop_container,
    position: 'relative',
    padding: [0, 40, 120, 40],
    paddingTop: VARIABLES.desktop_header_height,
  },
  contentWrapper: {
    paddingTop: 40,
    position: 'relative',
    '&:not(:last-child)': {
      marginBottom: '20px',
    },
  },

  [VARIABLES.tablet]: {
    wrapperHeader: {
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    wrapper: {
      userSelect: 'none',
      padding: [0, 30],
      paddingBottom: 60,
      paddingTop: VARIABLES.desktop_header_height + 45 + 19,
    },
    contentWrapper: {
      display: 'block',
      marginBottom: 0,
      paddingTop: 30,
    },
  },
  [VARIABLES.mobile]: {
    bgColor: {
      height: '100%',
    },
    wrapperHeader: {
      padding: 0,
    },
    wrapper: {
      padding: [0, 20],
      paddingBottom: 50,
      paddingTop: VARIABLES.mobile_header_height + 45 + 19,
    },
    contentWrapper: {
      paddingTop: 20,
      marginBottom: '10px',
    },
  },
})

interface Props {}

const TermsPage: React.FC<Props> = () => {
  const { chain, isConnected } = useAccount()

  const [smallHeader, setSmallHeader] = useState(false)
  const [isOpenWrongNetworkModal, setIsOpenWrongNetworkModal] = useState(false)
  const classes = styles()
  const dispatch = useDispatch()
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    dispatch(setLoading(false))
  })

  useEffect(() => {
    if (!isConnected) return

    if (chain?.id !== CHAINS[0].id) {
      setIsOpenWrongNetworkModal(true)
    } else {
      setIsOpenWrongNetworkModal(false)
    }
  }, [chain, isConnected])

  const listenScrollEvent = () => {
    if (window.scrollY > 50) {
      setSmallHeader(true)
    } else {
      setSmallHeader(false)
    }
  }

  return (
    <>
      <div className="bgContainer">
        <img src={bgImage} className="bgColor" alt="" />
      </div>
      <div className={classes.allContentWisible}>
        <div className={classes.bgColor}>
          <div
            className={
              smallHeader ? classes.wrapperSmallHeader : classes.wrapperHeader
            }
          >
            <Header smallHeader={smallHeader} />
          </div>
          <div className={classes.wrapper}>
            <div className={classes.contentWrapper}>
              <DocumentContent>
                <TermsContent />
              </DocumentContent>
            </div>
          </div>
          <SupportButton />
        </div>
        <Footer />
        {isOpenWrongNetworkModal && (
          <WrongNetworkModal
            closeModal={() => setIsOpenWrongNetworkModal(false)}
          />
        )}
      </div>
    </>
  )
}
export default TermsPage
