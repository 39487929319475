import React from 'react'
import { createUseStyles } from 'react-jss'
import { ReactComponent as ChevronIcon } from '../../img/chevron.svg'
import { ReactComponent as ErrorIcon } from '../../img/warning.svg'
import useComponentVisible from '../../hooks/useComponentVisible'
import { useAccount, useSwitchChain } from 'wagmi'

import { CHAINS, CHAINS_ICONS } from 'src/constants'
import { Tooltip } from 'antd'
import { VARIABLES } from 'src/constants/variables'

const styles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  networkDropdown: {
    boxSizing: 'border-box',
    padding: [0, 12],
    height: 36,
    borderRadius: 4,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'transparent',
    gap: 8,
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    transition: VARIABLES.element_transition,
    color: VARIABLES.white,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.10)',
    },

    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.10)',
    },
  },
  text: {
    fontFamily: VARIABLES.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 'normal',
    color: VARIABLES.white,
  },
  buttonName: {
    extend: 'text',
    userSelect: 'none',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  dropdown: {
    boxSizing: 'border-box',
    minWidth: 124,
    background: VARIABLES.purple,
    position: 'absolute',
    boxShadow: `0px 2px 2px ${VARIABLES.bg_dark}`,
    border: `1px solid ${VARIABLES.card_border_color}`,
    borderRadius: 4,
    top: 'calc(100% + 10px)',
    padding: 4,
    right: 0,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    margin: 0,
    userSelect: 'none',
  },
  smallDropdown: {
    extend: 'dropdown',
  },
  dropdownItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    gap: 10,
    padding: 8,
    cursor: 'pointer',
    transition: VARIABLES.element_transition,
    userSelect: 'none',

    '&:hover': {
      backgroundColor: VARIABLES.card_bg,
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 6,
    height: 6,
    marginLeft: 'auto',
  },
  statusIcon: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: VARIABLES.green,
    marginLeft: 'auto',
  },
  icon: {
    width: 16,
    height: 16,
  },
  arrow: {
    transition: VARIABLES.element_transition,
  },
  arrowActive: {
    transform: 'rotate(180deg)',
  },
  dropdownItemDisable: {
    cursor: 'not-allowed',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    gap: 10,
    color: '#A8A8B2',
  },
  textDisable: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#A8A8B2',
  },
  '@media (max-width: 767px)': {
    buttonName: {
      display: 'none',
    },
    networkDropdown: {
      minWidth: 'auto',
      padding: [0, 12],
    },
  },
})

interface Props {
  smallHeader: boolean
  isNftPage?: boolean
}

const NetworkDropdown: React.FC<Props> = ({ smallHeader, isNftPage }) => {
  const { chain: currentChain } = useAccount()
  const { chains, switchChain: switchNetwork } = useSwitchChain()
  const classes = styles()

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const handleOpen = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  const handleChangeNetwork = async (chain: any) => {
    await switchNetwork({
      chainId: chain.id,
    })
  }

  return (
    <>
      <div className={classes.wrapper} ref={ref}>
        <Tooltip
          overlayInnerStyle={{ boxShadow: 'none' }}
          placement="top"
          title={
            !isComponentVisible && currentChain?.id !== CHAINS[0].id ? (
              <span>Wrong Network</span>
            ) : null
          }
        >
          <div className={classes.networkDropdown} onClick={() => handleOpen()}>
            <div className={classes.iconWrapper}>
              {currentChain?.id !== CHAINS[0].id ? (
                <ErrorIcon />
              ) : (
                <img
                  className={classes.icon}
                  src={CHAINS_ICONS[currentChain?.id || 0]}
                  alt=""
                />
              )}
              <div className={classes.buttonName}>{currentChain?.name}</div>
            </div>
            <ChevronIcon
              className={`${classes.arrow} ${
                isComponentVisible && classes.arrowActive
              }`}
            />
          </div>
        </Tooltip>
        {isComponentVisible ? (
          <div className={classes.dropdown}>
            {chains.map((chain: any, index: number) => {
              return (
                <div
                  key={index}
                  className={classes.dropdownItem}
                  onClick={() => {
                    handleChangeNetwork(chain)
                  }}
                >
                  <img
                    className={classes.icon}
                    src={CHAINS_ICONS[chain?.id || -1]}
                    alt=""
                  />
                  <div className={classes.text}>{chain.name}</div>
                  <div className={classes.statusContainer}>
                    {chain?.id === currentChain?.id && (
                      <div className={classes.statusIcon}></div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default NetworkDropdown
