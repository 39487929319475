import React from 'react'
import { createUseStyles } from 'react-jss'
import { useAccount } from 'wagmi'

import { ReactComponent as NoImageIcon } from '../../img/no_image.svg'
import { VARIABLES } from 'src/constants/variables'
import { buttonStyle } from 'src/styles/button'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { BUY_NFT_LINK } from 'src/constants'

const styles = createUseStyles({
  mainBlock: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: [60, 40],
    borderRadius: 8,
    border: '1px solid rgba(255, 255, 255, 0.08);',
    backgroundColor: 'rgba(255, 255, 255, 0.03);',
    maxWidth: VARIABLES.desktop_container,
    marginTop: 60,
  },
  button: {
    ...buttonStyle,
    marginTop: 40,
    height: 48,
    padding: [0, 24],
    filter: VARIABLES.BLUR,
  },
  noConnectedMainText: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '110%',
    color: VARIABLES.white,
    letterSpacing: 0.24,
    marginTop: 15,
  },
  noConnectedImg: {
    width: 48,
    height: 48,
    color: VARIABLES.text_grey,
  },
  noConnectedSubText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    color: VARIABLES.text_grey,
    marginTop: 10,
  },
  [VARIABLES.tablet]: {
    mainBlock: {
      marginTop: 40,
    },
  },
  [VARIABLES.mobile]: {
    mainBlock: {},
    noConnectedImg: {
      width: 32,
      height: 32,
    },
    noConnectedMainText: {
      fontSize: 18,
    },
    noConnectedSubText: {
      marginTop: 13,
    },
    button: {
      marginTop: 20,
    },
  },
})

const MyNFTSNoConnected = () => {
  const classes = styles()
  const { isConnected } = useAccount()
  const { open: openWeb3Modal } = useWeb3Modal()

  return (
    <div className={classes.mainBlock}>
      <NoImageIcon className={classes.noConnectedImg} />
      <div className={classes.noConnectedMainText}>
        There are no NFTs here yet
      </div>
      <div className={classes.noConnectedSubText}>
        {isConnected
          ? 'You can buy NFTs right now'
          : 'Connect wallet to be able to stake your NFTs'}
      </div>
      {isConnected ? (
        <a
          className={classes.button}
          href={BUY_NFT_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Buy Now
        </a>
      ) : (
        <button className={classes.button} onClick={() => openWeb3Modal()}>
          Connect wallet
        </button>
      )}
    </div>
  )
}
export default MyNFTSNoConnected
