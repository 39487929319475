/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import StakingOverview from '../StakingOverview/StakingOverview'
import ClaimRewards from '../ClaimRewards/ClaimRewards'
import MyNFTS from '../MyNFTS/MyNFTS'
import { request } from '../../factory/axios'

import { useAccount } from 'wagmi'
import { VARIABLES } from 'src/constants/variables'
import { IStaking } from 'src/types/staking.interface'

const styles = createUseStyles({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 10,
    zIndex: 5,
  },
  mainTitle: {
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: '60px',
    color: '#FFFFFF',
    paddingBottom: 10,
  },
  supTitle: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
    color: '#A8A8B2',
    width: 780,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingBottom: 40,
  },
  mainBlock: {
    background:
      'linear-gradient(180deg, rgba(49, 57, 84, 0.8) 0%, rgba(53, 53, 81, 0.8) 100%)',
    boxShadow: 'inset 1px 1px 1px #42426A',
    borderRadius: 12,
    padding: '30px 0px',
    paddingBottom: 30,
    display: 'block',
    width: '100%',
  },
  overviewWrapper: {
    display: 'flex',
    gap: 10,
    width: '100%',
    boxSizing: 'border-box',
  },
  [VARIABLES.tablet]: {
    overviewWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
  },
})

const StakingNft = () => {
  const classes = styles()
  const { address } = useAccount()
  const [staking, setStaking] = useState<IStaking | null>(null)

  useEffect(() => {
    fetchStakingInfo()
  }, [address])

  const fetchStakingInfo = () => {
    let path = `nft/staking`

    if (address) {
      path = path + `?address=${address}`
    }
    request({
      method: 'get',
      path,
    })
      .then((req) => {
        const result: IStaking = req?.data?.data

        setStaking(result)
      })
      .catch((error: any) => console.log('Error fetching staking info:', error))
  }

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.overviewWrapper}>
        <StakingOverview staking={staking} />
        <ClaimRewards staking={staking} refetchStaking={fetchStakingInfo} />
      </div>
      <MyNFTS refetchStaking={fetchStakingInfo} />
    </div>
  )
}

export default StakingNft
