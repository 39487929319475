import { useState, useEffect } from 'react'

export default function useResize() {
  const [width, setWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    currentWidth: width,
    isDesktop: width > 1023,
    isTablet: width < 1024,
    isMobile: width < 768,
  }
}
