import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { WagmiProvider } from 'wagmi'

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CHAINS } from './constants'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_X_WALLETCONNECT_PROJECT_ID

// 2. Create wagmiConfig
const metadata = {
  name: 'Casino TYCOON',
  url: 'https://casinotycoon.io',
  description:
    'Join Casino TYCOON, the pioneering NFT crypto-casino. Buy, stake, and earn with exclusive NFTs representing digital casino slots. Transform your gaming experience into passive income. Explore the future of blockchain gaming now!',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const chains = CHAINS

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  allowUnsupportedChain: true,
  // defaultChain: CHAINS[0],
  // enableAnalytics: false,
  themeVariables: {
    '--w3m-z-index': 9999,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
export const history = createBrowserHistory()

root.render(
  <WagmiProvider config={wagmiConfig} >
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <React.StrictMode>
          <BrowserRouter history={history}>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </Provider>
    </QueryClientProvider>
  </WagmiProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
