/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { ReactComponent as RefreshIcon } from '../../img/NFT/refresh.svg'
import { ReactComponent as StakeIcon } from '../../img/NFT/green-stake.svg'
import { request } from '../../factory/axios'
import { setUserNfts } from '../../redux/userNfts'
import { useDispatch, useSelector } from 'react-redux'
import { NFT_NOTIFICATIONS } from '../../constants/notifications'
import { useAccount, useClient, useWriteContract } from 'wagmi'

import toast from 'react-hot-toast'
import MyNFTSNoConnected from '../MyNFTSNoConnected/MyNFTSNoConnected'
import { VARIABLES } from 'src/constants/variables'

import NftCard from '../NFTCard/NFTCard'
import NftCardShimmer from '../NFTCard/NFTCardShimmer'

import Pagination from '../Pagination/Pagination'
import { buttonStyle } from 'src/styles/button'
import { INFT, INFTResponse } from 'src/types/nft.interface'
import {
  BUY_NFT_LINK,
  CHAINS,
  NFT_CONTRACT_ABI,
  NFT_CONTRACT_ADDRESS,
  SCAN_URL,
} from 'src/constants'
import useToast from 'src/hooks/useToast'
import useResize from 'src/hooks/useResize'
import { waitForTransactionReceipt } from 'viem/actions'

const styles = createUseStyles({
  titleText: {
    fontWeight: 500,
    fontSize: 42,
    lineHeight: '120%',
    color: VARIABLES.white,
    letterSpacing: 2.24,
  },
  subTitleText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    color: VARIABLES.text_grey,
    maxWidth: 665,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    zIndex: 5,
    position: 'relative',
  },
  subTitleNFTText: {
    paddingTop: 15,
  },
  mainBlock: {
    marginTop: 120,
    marginBottom: 60,
    position: 'relative',
    zIndex: 10,
  },
  refreshIcon: {
    transition: 'all 1s ease',

    '&.active': {
      animation: '$rotate 1s infinite linear',
    },
  },
  '@keyframes rotate': {
    from: {},
    to: {
      transform: 'rotate(360deg)',
    },
  },
  documentsWrapper: {
    fontFamily: VARIABLES.fontFamily,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',
    color: VARIABLES.white,
    transition: VARIABLES.element_transition,
    fontSize: 16,
    fontWeight: 400,

    '&:hover': {
      color: VARIABLES.text_grey,

      '& $refreshIcon': {
        transform: 'rotate(360deg)',
      },
    },

    '&:disabled': {
      color: VARIABLES.text_grey,
      cursor: 'not-allowed',
      pointerEvents: 'none',
      opacity: 0.5,
    },
    background: 'transparent',
  },
  nftCardWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(325px,1fr))',
    alignItems: 'center',
    gap: 20,
    paddingTop: 60,
    flexDirection: 'row',
    justifyContent: 'center',
    justifyItems: 'flex-start',
    position: 'relative',
    zIndex: 5,
  },
  galleryImg: {
    width: 262,
    height: 262,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 30,
  },
  greenText: {
    color: '#53CBC8',
    fontSize: '14px',
    extend: 'subTitleText',
  },
  unstakeText: {
    color: '#A8A8B2',
    fontSize: '14px',
    extend: 'subTitleText',
  },
  allButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 21,
  },
  buyButton: {
    ...buttonStyle,
    height: 48,
    padding: [0, 24],
    filter: 'blur(2px)',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24,
  },
  paginationText: {
    color: VARIABLES.text_grey,
    fontSize: 14,
    fontWeight: 500,
  },
  icon: {
    width: 20,
    height: 20,
  },
  [VARIABLES.tablet]: {
    mainBlock: {
      marginTop: 55,
      marginBottom: 0,
    },
    buttonsWrapper: {
      marginTop: 30,
      justifyContent: 'space-between',
    },
    allButtonsWrapper: {
      width: '100%',
      justifyContent: 'space-between',
    },
    documentsWrapper: {
      padding: 0,
    },
    nftCardWrapper: {
      display: 'flex',
      overflowX: 'scroll',
      flexWrap: 'nowrap',
      width: 'calc(100vw - 60px)',
      transform: 'translateX(-30px)',
      paddingLeft: 30,
      paddingRight: 30,
      boxSizing: 'content-box',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      paddingTop: 40,
      justifyContent: 'flex-start',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },

    paginationWrapper: {
      display: 'none',
    },
  },
  [VARIABLES.mobile]: {
    titleWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    titleText: {
      fontSize: 24,
      letterSpacing: 0.4,
    },
    mainBlock: {
      marginTop: 62,
    },
    buttonsWrapper: {
      flexDirection: 'column-reverse',
      marginTop: 20,
      gap: 20,
    },
    buyButton: {
      width: '100%',
      order: 0,
    },
    documentsWrapper: {
      order: 1,
    },
    allButtonsWrapper: {
      gap: 10,
      rowGap: 20,
      width: '100%',
      justifyContent: 'space-between',
    },
    icon: {
      width: 16,
      height: 16,
    },
    nftCardWrapper: {
      width: 'calc(100vw - 40px)',
      transform: 'translateX(-20px)',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
})

interface Props {
  refetchStaking: () => void
}
interface IPagination {
  page: number
  limit: number
  pages: number
  total: number
}

const MyNFTS: React.FC<Props> = ({ refetchStaking }) => {
  const classes = styles()
  const client = useClient()

  const [disableStake, setDisableStake] = useState(false)
  const [loadingNFT, setLoadingNFT] = useState(true)
  const [allNFT, setAllNFT] = useState<INFT[]>([])
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    pages: 1,
    limit: 12,
    total: 0,
  })
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false)
  const [needRefetch, setNeedRefetch] = useState<boolean>(false)

  const { isTablet } = useResize()
  const { address, chain } = useAccount()
  const { nft } = useSelector((state: any) => state.userNftsReducer)
  const { notification } = useToast()
  const { writeContractAsync } = useWriteContract()

  const dispatch = useDispatch()
  const mobileScrollContainer = useRef<any>(null)

  const isStakedNft = allNFT.find((item: INFT) => item.isLocked)
  const isUnstakedNft = allNFT.find((item: INFT) => !item.isLocked)

  const handleScroll = async () => {
    if (window?.innerWidth > 1023) return

    if (mobileScrollContainer?.current) {
      const scrollLeft = mobileScrollContainer?.current?.scrollLeft
      const clientWith = mobileScrollContainer?.current?.clientWidth
      const scrollWidth = mobileScrollContainer?.current?.scrollWidth
      const distanceFromStart = scrollLeft + clientWith
      const distanceToEnd = scrollWidth - distanceFromStart

      if (isLoadMore) return

      if (distanceToEnd < 300) {
        setIsLoadMore(true)
        await loadMoreNFT()
      }
    }
  }

  const onlyUnique = (value: any, index: number, array: any[]) => {
    return array.indexOf(value) === index
  }

  const getUnstakedIds = () => {
    const ids = allNFT
      .filter((item: INFT) => !item.isLocked)
      .map((item: INFT) => item.tokenId)
      .filter(onlyUnique)

    return {
      ids,
      amounts: ids.map(
        (id: number) =>
          allNFT.filter((item: INFT) => !item.isLocked && item.tokenId === id)
            .length
      ),
    }
  }

  const getStakedIds = () => {
    const ids = allNFT
      .filter((item: INFT) => item.isLocked)
      .map((item: INFT) => item.tokenId)
      .filter(onlyUnique)

    return {
      ids,
      amounts: ids.map(
        (id: number) =>
          allNFT.filter((item: INFT) => item.isLocked && item.tokenId === id)
            .length
      ),
    }
  }

  useEffect(() => {
    if (address) {
      fetchNFT()
      fetchAllNFT()
    } else {
      dispatch(setUserNfts([]))
      setLoadingNFT(false)
    }
  }, [address])

  useEffect(() => {
    if (needRefetch) {
      refetchNFTAfterStaking()
      refetchStaking()
      setNeedRefetch(false)
    }
  }, [needRefetch])

  const fetchNFT = async (
    page: number = 1,
    isLoadMore: boolean = false,
    withLoader: boolean = true
  ) => {
    if (withLoader) {
      setLoadingNFT(true)
    }

    await request({
      method: 'get',
      path: `nft/list/${address}?limit=${pagination.limit}&page=${page}`,
    })
      .then((req) => {
        const result: INFTResponse = req?.data?.data
        const nfts = result.entities

        isLoadMore
          ? dispatch(setUserNfts([...nft, ...nfts]))
          : dispatch(setUserNfts(nfts))

        setPagination({
          ...pagination,
          page,
          total: result.countItems,
          pages: result.pages,
        })
      })
      .finally(() => {
        setLoadingNFT(false)
      })
      .catch((error: any) => console.log('Error fetching NFT:', error))
  }

  const refetchNFT = async () => {
    if (isTablet) {
      await fetchNFT(1, false, false)

      if (mobileScrollContainer?.current) {
        mobileScrollContainer.current.scrollTo({ left: 0, behavior: 'smooth' })
        setIsLoadMore(false)
      }
    } else {
      await fetchNFT(pagination.page)
    }
    await fetchAllNFT()
  }

  const refetchNFTAfterStaking = async () => {
    if (isTablet) {
      await fetchNFT(1, false, false)

      if (mobileScrollContainer?.current) {
        mobileScrollContainer.current.scrollTo({ left: 0, behavior: 'smooth' })
        setIsLoadMore(false)
      }
    } else {
      await fetchNFT(pagination.page, false, false)
    }

    await fetchAllNFT()
  }

  const loadMoreNFT = async () => {
    if (pagination.page >= pagination.pages) return

    await fetchNFT(pagination.page + 1, true, false)
    setIsLoadMore(false)
  }

  const handlePageChange = async ({ selected }: { selected: number }) => {
    await fetchNFT(selected + 1)
  }

  const fetchAllNFT = async () => {
    await request({
      method: 'get',
      path: `nft/list/${address}?limit=9999&page=1`,
    })
      .then((req) => {
        const result: INFTResponse = req?.data?.data
        const nfts = result.entities

        setAllNFT(nfts)
      })
      .catch((error: any) => console.log('Error fetching all NFTs:', error))
  }

  const stakeNFT = async (id: number) => {
    onStartStaking(true)

    try {
      const hash = await writeContractAsync({
        //@ts-ignore
        address: NFT_CONTRACT_ADDRESS,
        abi: NFT_CONTRACT_ABI,
        functionName: 'stake',
        args: [id, 1],
      })

      onSubmitStaking(hash, true)

      if (client) {
        await waitForTransactionReceipt(client, {
          hash,
        })

        onSuccessfulStaking(hash, true)
      }
    } catch (error) {
      onErrorStaking(true)
    }
  }

  const unstakeNFT = async (id: number) => {
    onStartStaking(false)

    try {
      const hash = await writeContractAsync({
        //@ts-ignore
        address: NFT_CONTRACT_ADDRESS,
        abi: NFT_CONTRACT_ABI,
        functionName: 'unstake',
        args: [id, 1],
      })

      onSubmitStaking(hash, false)

      if (client) {
        await waitForTransactionReceipt(client, {
          hash,
        })

        onSuccessfulStaking(hash, false)
      }

      // await waitForTransaction({
      //   hash,
      // })

      // onSuccessfulStaking(hash, false)
    } catch (error) {
      onErrorStaking(false)
    }
  }

  const stakeAll = async () => {
    if (disableStake) return

    onStartStaking(true)

    try {
      const unstakedIds = getUnstakedIds()
      const hash = await writeContractAsync({
        //@ts-ignore
        address: NFT_CONTRACT_ADDRESS,
        abi: NFT_CONTRACT_ABI,
        functionName: 'stakeBatch',
        args: [unstakedIds.ids, unstakedIds.amounts],
      })

      onSubmitStaking(hash, true)

      if (client) {
        await waitForTransactionReceipt(client, {
          hash,
        })

        onSuccessfulStaking(hash, true)
      }
    } catch (error) {
      onErrorStaking(true)
    }
  }

  const unstakeAll = async () => {
    if (disableStake) return

    onStartStaking(false)

    try {
      const stakedIds = getStakedIds()

      const hash = await writeContractAsync({
        //@ts-ignore
        address: NFT_CONTRACT_ADDRESS,
        abi: NFT_CONTRACT_ABI,
        functionName: 'unstakeBatch',
        args: [stakedIds.ids, stakedIds.amounts],
      })

      onSubmitStaking(hash, false)

      if (client) {
        await waitForTransactionReceipt(client, {
          hash,
        })

        onSuccessfulStaking(hash, false)
      }
    } catch (error) {
      onErrorStaking(false)
    }
  }

  const onStartStaking = (isStake: boolean) => {
    setDisableStake(true)

    notification.info({
      title: 'Info',
      text: isStake
        ? NFT_NOTIFICATIONS.confirmStake
        : NFT_NOTIFICATIONS.confirmUnstake,
    })
  }

  const onSuccessfulStaking = (hash: string, isStake: boolean) => {
    setTimeout(() => {
    toast.remove()
    notification.success({
      title: 'Success',
      text: (
        <>
          {isStake
            ? NFT_NOTIFICATIONS.successStake
            : NFT_NOTIFICATIONS.successUnstake}{' '}
          <a href={`${SCAN_URL}/tx/${hash}`} target="_blank" rel="noreferrer">
            Transaction ↗
          </a>
        </>
      ),
    })
    setDisableStake(false)

    setNeedRefetch(true)
    }, 5000)
  }

  const onErrorStaking = (isStake: boolean) => {
    toast.remove()
    setDisableStake(false)

    notification.error({
      title: 'Error',
      text: isStake
        ? NFT_NOTIFICATIONS.errorStake
        : NFT_NOTIFICATIONS.errorUnstake,
    })
  }

  const onSubmitStaking = (hash: string, isStake: boolean) => {
    toast.remove()
    notification.info({
      title: 'Info',
      text: (
        <>
          {isStake
            ? NFT_NOTIFICATIONS.submittedStake
            : NFT_NOTIFICATIONS.submittedUnstake}{' '}
          <a href={`${SCAN_URL}/tx/${hash}`} target="_blank" rel="noreferrer">
            Transaction ↗
          </a>
        </>
      ),
    })
  }

  const Buttons = () => {
    return (
      <div className={classes.buttonsWrapper}>
        <div className={classes.allButtonsWrapper}>
          <button
            onClick={() => refetchNFT()}
            className={classes.documentsWrapper}
            disabled={!nft.length}
          >
            <RefreshIcon
              className={[
                classes.refreshIcon,
                classes.icon,
                nft.length && loadingNFT && 'active',
              ].join(' ')}
            />
            Refresh
          </button>
          <button
            className={classes.documentsWrapper}
            disabled={
              !nft.length ||
              !isStakedNft ||
              disableStake ||
              chain?.id !== CHAINS[0].id
            }
            onClick={unstakeAll}
          >
            <StakeIcon className={classes.icon} />
            Unstake All
          </button>
          <button
            className={classes.documentsWrapper}
            disabled={
              !nft.length ||
              !isUnstakedNft ||
              disableStake ||
              chain?.id !== CHAINS[0].id
            }
            onClick={stakeAll}
          >
            <StakeIcon className={classes.icon} />
            Stake All
          </button>
          <a
            className={classes.buyButton}
            href={BUY_NFT_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Buy Now
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.mainBlock}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleText}>My NFTs</div>
        {!isTablet && <Buttons />}
      </div>
      <div
        className={[classes.subTitleText, classes.subTitleNFTText].join(' ')}
      >
        Available NFTs for staking
      </div>
      {isTablet && <Buttons />}

      {loadingNFT ? (
        <div className={classes.nftCardWrapper}>
          {Array.from(Array(pagination.limit).keys()).map((key) => (
            <NftCardShimmer key={key} />
          ))}
        </div>
      ) : nft.length ? (
        <div
          ref={mobileScrollContainer}
          className={classes.nftCardWrapper}
          onScroll={handleScroll}
        >
          {nft.map((item: INFT, index: number) => (
            <NftCard
              data={item as INFT}
              key={`${item.tokenId}-${index}`}
              disableStake={disableStake}
              stake={stakeNFT}
              unstake={unstakeNFT}
              isWrongNetwork={chain?.id !== CHAINS[0].id}
              forMarketplace={false}
            />
          ))}
        </div>
      ) : (
        <MyNFTSNoConnected />
      )}
      {nft.length ? (
        <div className={classes.paginationWrapper}>
          <div className={classes.paginationText}>
            Showing {pagination.page * pagination.limit - pagination.limit + 1}{' '}
            -{' '}
            {pagination.page * pagination.limit > pagination.total
              ? pagination.total
              : pagination.page * pagination.limit}{' '}
            out of {pagination.total}
          </div>
          {pagination.pages > 1 && (
            <div>
              <Pagination
                pageCount={pagination.pages}
                initialPage={pagination.page - 1}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}
export default MyNFTS
