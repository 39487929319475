import React from 'react'
import { createUseStyles } from 'react-jss'
import WalletButton from '../WalletButton/WalletButton'
import NetworkDropdown from '../NetworkDropdown/NetworkDropdown'

import { ReactComponent as TelegramIcon } from '../../img/telegram.svg'
import { ReactComponent as TwitterIcon } from '../../img/x.svg'
import { ReactComponent as Logo } from '../../img/logo.svg'
import { ReactComponent as LogoMobile } from '../../img/logo_mobile.svg'

import { useAccount } from 'wagmi'
import { VARIABLES } from 'src/constants/variables'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import useResize from 'src/hooks/useResize'

const styles = createUseStyles({
  header: {
    display: 'flex',
    height: VARIABLES.desktop_header_height,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '0 auto',
    padding: [0, 40],
    boxSizing: 'border-box',
  },
  smallHeader: {
    extend: 'header',
    borderBottom: `1px solid ${VARIABLES.card_border_color}`,
  },

  headerContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    maxWidth: VARIABLES.desktop_container,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  socials: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    paddingRight: 10,
  },
  socialIcon: {
    color: VARIABLES.white,
    width: 20,
    height: 20,
    transition: VARIABLES.element_transition,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      color: VARIABLES.text_grey,
    },
  },
  socialIconTwitter: {
    extend: 'socialIcon',
    width: 16,
    height: 16,
  },
  logo: {
    cursor: 'pointer',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
    marginRight: 'auto',
    marginLeft: 80,
  },
  link: {
    color: VARIABLES.text_grey,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: 0.18,
    textDecoration: 'none',

    '&.active': {
      color: VARIABLES.white,
    },
  },
  navMobile: {
    display: 'none',
  },
  [VARIABLES.tablet]: {
    header: {
      padding: [0, 30],
    },
    smallHeader: {
      padding: [0, 30],
    },
    buttonWrapper: {
      gap: 20,
    },
    socials: {
      gap: 30,
    },
    nav: {
      display: 'none',
    },
    navMobile: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 40,
      top: '100%',
      height: 44,
      width: '100%',
      backgroundColor: VARIABLES.purple,
      borderTop: `1px solid ${VARIABLES.primary}`,
    },
  },
  [VARIABLES.mobile]: {
    logo: {
      width: 80,
    },
    header: {
      padding: [0, 20],
      height: VARIABLES.mobile_header_height,
    },
    smallHeader: {
      padding: [0, 20],
      height: VARIABLES.mobile_header_height,
    },
    buttonWrapper: {
      gap: 8,
    },
    socials: {
      display: 'none',
    },
  },
})

interface Props {
  smallHeader: boolean
}

const Header: React.FC<Props> = ({ smallHeader }) => {
  const classes = styles()
  const { isConnected } = useAccount()
  const { pathname } = useLocation()
  const { isMobile } = useResize()
  let navigate = useNavigate()

  return (
    <header className={smallHeader ? classes.smallHeader : classes.header}>
      <div className={classes.headerContainer}>
        {isMobile ? (
          <LogoMobile
            className={classes.logo}
            onClick={() => {
              if (pathname !== '/') {
                navigate('/')
                return
              }

              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
          />
        ) : (
          <Logo
            className={classes.logo}
            onClick={() => {
              if (pathname !== '/') {
                navigate('/')
                return
              }

              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
          />
        )}
        <div className={classes.nav}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${isActive ? `${classes.link} active` : classes.link} `
            }
          >
            Marketplace
          </NavLink>
          <NavLink
            to="/my-nfts"
            className={({ isActive }) =>
              `${isActive ? `${classes.link} active` : classes.link} `
            }
          >
            My NFTs
          </NavLink>
        </div>
        <div className={classes.buttonWrapper}>
          <div className={classes.socials}>
            {/* <a
              href="#"
              className={classes.socialIcon}
              target="_blank"
              rel="noreferrer"
            >
              <DiscordIcon className={classes.socialIcon} />
            </a> */}
            <a
              href="https://t.me/Casino_TYCOON"
              className={classes.socialIcon}
              target="_blank"
              rel="noreferrer"
            >
              <TelegramIcon className={classes.socialIcon} />
            </a>
            <a
              href="https://twitter.com/casino_tycoon"
              className={classes.socialIcon}
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon className={`${classes.socialIconTwitter}`} />
            </a>
          </div>
          {isConnected && <NetworkDropdown smallHeader={smallHeader} />}
          <WalletButton />
        </div>
      </div>
      <div className={classes.navMobile}>
        <NavLink
          to="/"
          className={({ isActive }) =>
            `${isActive ? `${classes.link} active` : classes.link} `
          }
        >
          Marketplace
        </NavLink>
        <NavLink to="/my-nfts" className={classes.link}>
          My NFTs
        </NavLink>
      </div>
    </header>
  )
}

export default Header
