import React from 'react'
import { createUseStyles } from 'react-jss'

import { VARIABLES } from 'src/constants/variables'

import { ReactComponent as CandyIcon } from '../../img/Candy.svg'

const styles = createUseStyles({
  wrapper: {
    marginTop: 150,
    marginBottom: 144,
  },
  container: {
    backgroundColor: VARIABLES.card_bg,
    border: `1px solid ${VARIABLES.card_border_color}`,
    padding: [50, 0],
    display: 'grid',
    gridTemplateColumns: '54% auto',
    alignItems: 'center',
    color: VARIABLES.white,
    borderRadius: 8,
  },
  infoContainer: {
    borderRight: `1px solid ${VARIABLES.card_border_color}`,
    padding: [16, 0],
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 460,
    marginLeft: 70,
  },
  title: {
    fontSize: 42,
    fontWeight: 700,
    lineHeight: '69px',
    margin: 0,
  },
  description: {
    color: VARIABLES.text_grey,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '150%',
    margin: 0,
    marginTop: 10,
  },
  imgContainer: {
    height: 170,
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 27,
  },
  img: {
    width: 219,
    height: 170,
    color: VARIABLES.pink,
  },
  [VARIABLES.tablet]: {
    wrapper: {
      marginTop: 53,
      marginBottom: 110,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
    },
    content: {
      margin: 0,
    },
    infoContainer: {
      borderRight: 'none',
      borderBottom: `1px solid ${VARIABLES.card_border_color}`,
      padding: 0,
      width: '100%',
      justifyContent: 'flex-start',
      paddingBottom: 20,
    },
    imgContainer: {
      padding: [32, 0, 0],
      margin: 0,
    },
    description: {
      maxWidth: 460,
    },
  },
  [VARIABLES.mobile]: {
    wrapper: {
      marginBottom: 100,
    },
    title: {
      fontSize: 24,
      lineHeight: 'normal',
    },
    description: {
      fontSize: 14,
    },
    infoContainer: {
      paddingBottom: 20,
    },
    imgContainer: {
      height: 113,
      paddingTop: 20,
    },
    img: {
      width: 146,
      height: 113,
    },
  },
})

const Partners = () => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <div className={classes.content}>
            <p className={classes.title}>Partners</p>
            <p className={classes.description}>
              Thanks to our exclusive partners, Casino TYCOON NFTs owners can
              directly share in the profits of real-world casinos
            </p>
          </div>
        </div>
        <div className={classes.imgContainer}>
          <CandyIcon className={classes.img} />
        </div>
      </div>
    </div>
  )
}

export default Partners
