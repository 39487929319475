import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'
import { ReactComponent as CloseIcon } from '../../img/close.svg'
import { getIPFSImage } from 'src/utils'
import { INFTMarketplace } from 'src/types/nft.interface'

import { ReactComponent as ImageIcon } from '../../img/imgIcon.svg'
import { ReactComponent as ETHIcon } from '../../img/chains/ethereum.svg'
import Counter from '../Counter/Counter'
import { buttonStyle } from 'src/styles/button'

import { useAccount, useClient, useSwitchChain, useWriteContract } from 'wagmi'
import useToast from 'src/hooks/useToast'
import { useWeb3Modal } from '@web3modal/wagmi/react'

import { NFT_SALE_ABI, NFT_SALE_CONTRACT_ADDRESS, CHAINS } from 'src/constants'
import { request } from 'src/factory/axios'
import SmallLoaderIcon from '../SmallLoaderIcon/SmallLoaderIcon'
import toast from 'react-hot-toast'
import { parseEther } from 'viem'
import { waitForTransactionReceipt } from 'viem/actions'

const styles = createUseStyles({
  modalBg: {
    width: '100vw',
    background: VARIABLES.backdrop,
    height: 'var(--app-height)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9998,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  modalWrapper: {
    width: 360,
    maxHeight: '100vh',
  },
  modal: {
    background: VARIABLES.purple,
    borderRadius: 8,
    padding: [30, 20, 20],
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    flexShrink: 0,
  },
  container: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  imgContainer: {
    width: '100%',
    height: 283,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${VARIABLES.card_border_color}`,
  },
  nftImg: {
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'top center',
    width: '100%',
    height: '100%',
  },
  placeholderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    color: VARIABLES.text_grey,
    gap: 8,
  },
  placeholderIcon: {
    width: 24,
    height: 24,
  },
  placeholderText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    margin: 0,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '110%',
    letterSpacing: 0.24,
    color: VARIABLES.white,
  },
  closeButton: {
    position: 'absolute',
    top: 32,
    right: 14,
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: VARIABLES.white,
    cursor: 'pointer',
    transition: VARIABLES.element_transition,

    '&:hover': {
      color: VARIABLES.text_grey,
    },
  },
  closeIcon: {
    width: 20,
    height: 20,
  },

  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  priceWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: -3,
    marginBottom: 24,
    gap: 4,
  },
  priceLabel: {
    fontSize: 16,
    lineHeight: '148%',
    color: VARIABLES.text_grey,
    minHeight: 16,
    fontWeight: 500,
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  price: {
    fontSize: 16,
    lineHeight: '148%',
    minHeight: 16,
    fontWeight: 700,
    letterSpacing: 0.16,
    color: VARIABLES.white,
    margin: 0,
  },
  priceSale: {
    extend: 'price',
    color: VARIABLES.pink,
    textDecoration: 'line-through',
    fontWeight: 500,
  },
  discount: {
    color: VARIABLES.green,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '110%',
  },
  usdTotal: {
    color: VARIABLES.text_grey,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '110%',
    letterSpacing: 0.14,
  },
  nftTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: VARIABLES.white,
    lineHeight: '110%',
    letterSpacing: 0.24,
    textAlign: 'center',
    margin: [10, 0, 0, 0],
  },
  choose: {
    color: VARIABLES.text_grey,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.16,
    textAlign: 'center',
    margin: [15, 0, 20, 0],
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: VARIABLES.primary,
    margin: [20, 0, 20, 0],
  },
  button: {
    ...buttonStyle,
    height: 48,
    padding: [0, 24],
    width: '100%',
    minHeight: 48,
    marginTop: 'auto',
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  icon: {
    width: 19,
    height: 19,
  },
})

interface Props {
  onClose: () => void
  nft: INFTMarketplace
  isDiscount?: boolean
  discount?: number
  availableOnDiscount?: number
}

const BuyNFTModal: React.FC<Props> = ({
  onClose,
  nft,
  isDiscount = false,
  discount = 0,
  availableOnDiscount = 0,
}) => {
  const classes = styles()
  const { address, isConnected, chain } = useAccount()
  const { open: openWeb3Modal } = useWeb3Modal()
  const { notification } = useToast()
  const { switchChain: switchNetwork } = useSwitchChain()
  const { writeContractAsync } = useWriteContract()
  const client = useClient()

  const [data, setData] = useState<INFTMarketplace>(nft)
  const [isImgError, setIsImgError] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [quantity, setQuantity] = useState(1)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const videoUrl = useMemo(() => {
    return getIPFSImage(nft?.ipfsImage)
  }, [nft])

  useEffect(() => {
    refetchNFT()
  }, [address])

  const refetchNFT = async () => {
    await request({
      method: 'get',
      path: `sales/${data._id}`,
    })
      .then((req) => {
        const result: INFTMarketplace = req?.data?.data

        setData(result)
      })
      .catch((error: any) => console.log('Error fetching NFT:', error))
  }

  const buy = async () => {
    if (isDisabled) return
    setIsDisabled(true)

    try {
      toast.remove()
      notification.info({
        title: 'Info',
        text: "Start buying NFTs. It's take some time. Please wait.",
      })

      const res = await request({
        method: 'get',
        path: `sales/signature?tokenId=${
          data.tokenId
        }&address=${address?.toLowerCase()}&amount=${quantity}`,
      })

      const signature = res.data.data

      const args = [
        signature.tokenId,
        signature.price,
        quantity,
        signature.roundId,
        signature.signature,
      ]

      const value = parseEther(
        (isDiscount
          ? data.price * ((100 - discount) / 100) * quantity
          : data.price * quantity
        ).toString()
      )

      const hash = await writeContractAsync({
        //@ts-ignore
        address: NFT_SALE_CONTRACT_ADDRESS,
        abi: NFT_SALE_ABI,
        functionName: 'purchase',
        args,
        value,
      })

      if (client) {
        await waitForTransactionReceipt(client, {
          hash,
        })

        setTimeout(async () => {
          toast.remove()
          notification.success({
            title: 'Success',
            text: 'NFTs bought successfully.',
          })

          setData((prev) => ({ ...prev, available: prev.available - quantity }))
          setQuantity(1)
          setIsDisabled(false)
        }, 5000)
      }
    } catch (e) {
      console.log('ERROR:', e)

      if (
        e?.response?.data?.message?.includes(
          'Not enough tokens in whitelist available'
        )
      ) {
        toast.remove()
        notification.error({
          title: 'Error',
          text: `You can buy only ${availableOnDiscount} NFT with discount.`,
        })
        setIsDisabled(false)
        return
      }

      if (e.message.includes('Exceeds max amount to buy')) {
        toast.remove()
        notification.error({
          title: 'Error',
          text: 'Oops. Select a new quantity.',
        })
        refetchNFT()
      } else if (e.message.includes('insufficient funds for gas')) {
        toast.remove()
        notification.error({
          title: 'Error',
          text: 'You have not enough ETH to buy this NFT.',
        })
      } else {
        toast.remove()
        notification.error({
          title: 'Error',
          text: 'Oops, something went wrong',
        })
      }
      setIsDisabled(false)
    }
  }

  const handleQuantity = (value: number) => {
    setQuantity(value)
  }

  const handleSwitchNetwork = async () => {
    try {
      await switchNetwork({
        chainId: CHAINS[0].id,
      })
    } catch (e) {}
  }

  return (
    <div className={classes.modalBg} onClick={onClose}>
      <div className={classes.modalWrapper}>
        <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
          <button className={classes.closeButton} onClick={onClose}>
            <CloseIcon className={classes.closeIcon} />
          </button>
          <div className={classes.title}>Buy NFT</div>

          <div className={classes.container}>
            <div className={classes.imgContainer}>
              {!isImgError ? (
                <video
                  src={videoUrl}
                  className={classes.nftImg}
                  autoPlay={true}
                  loop
                  muted
                  playsInline
                  controls={false}
                  preload="auto"
                ></video>
              ) : (
                <div className={classes.placeholderWrapper}>
                  <ImageIcon className={classes.placeholderIcon} />
                  <p className={classes.placeholderText}>
                    Can’t Download Image
                  </p>
                </div>
              )}
            </div>
            <p className={classes.nftTitle}>{data?.name || '-'}</p>
            <p className={classes.choose}>Choose the quantity of NFTs</p>

            <Counter
              max={data.available}
              onChange={handleQuantity}
              disable={!data.available}
            />

            <div className={classes.divider}></div>
            <div className={classes.priceWrapper}>
              <div className={classes.row}>
                <div className={classes.priceBlock}>
                  <div className={classes.priceLabel}>Total price</div>
                  {isDiscount && (
                    <div className={classes.discount}>
                      {discount}% Discount
                    </div>
                  )}
                </div>
                <div className={classes.priceBlock}>
                  <div className={classes.priceContainer}>
                    <ETHIcon className={classes.icon} />
                    {isDiscount && (
                      <p className={classes.priceSale}>
                        {data.price * quantity}
                      </p>
                    )}
                    <p className={classes.price}>
                      {' '}
                      {isDiscount
                        ? data.price * ((100 - discount) / 100) * quantity
                        : data.price * quantity}
                    </p>
                  </div>
                  {/* <div className={classes.usdTotal}>~ $8590.66</div> */}
                </div>
              </div>
              <div className={classes.row}></div>
            </div>
            {isConnected ? (
              <>
                {chain?.id !== CHAINS[0].id ? (
                  <button
                    className={classes.button}
                    onClick={handleSwitchNetwork}
                  >
                    Switch Network
                  </button>
                ) : (
                  <>
                    <button
                      className={classes.button}
                      onClick={buy}
                      disabled={isDisabled || !data.available}
                    >
                      {isDisabled ? (
                        <SmallLoaderIcon />
                      ) : (
                        <>{!data.available ? 'Sold out' : 'Buy'}</>
                      )}
                    </button>
                  </>
                )}
              </>
            ) : (
              <button
                className={classes.button}
                onClick={() => openWeb3Modal()}
              >
                Connect Wallet
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyNFTModal
