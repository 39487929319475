import polygon from 'src/img/chains/polygon.svg'
import ethereum from 'src/img/chains/ethereum.svg'

interface IconMap {
  [id: number]: string
}

const CHAINS_ICONS: IconMap = {
  80001: polygon,
  137: polygon,
  1: ethereum,
  11155111: ethereum,
}

export { CHAINS_ICONS }
