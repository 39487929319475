import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'
import { Tooltip } from 'antd'

const styles = createUseStyles({
  wrapper: {
    backgroundColor: VARIABLES.card_bg,
    borderRadius: 4,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    height: 24,
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    color: VARIABLES.white,
    whiteSpace: 'nowrap',
  },
  slider: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  sliderRail: {
    height: 10,
    width: '100%',
    backgroundColor: VARIABLES.grey,
    borderRadius: 10,
    position: 'relative',
  },
  sliderBar: {
    height: '100%',
    width: 0,
    backgroundColor: VARIABLES.green,
    borderRadius: [10, 0, 0, 10],
    transition: 'all 0.3s linear',
  },
  sliderPoint: {
    width: 12,
    height: 12,
    position: 'absolute',
    top: '50%',
    backgroundColor: VARIABLES.bg,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    transform: 'translate(-75%, -50%)',
    border: `4px solid ${VARIABLES.text_grey}`,
    transition: 'all 0.3s linear',
  },
  '@media (max-width: 1023px)': {
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '70px 1fr',
    },
  },
  '@media (max-width: 767px)': {
    wrapper: {
      gap: 13,
    },
  },
})

interface IProps {
  label: string
  value: number
  className?: string
}

const AssetProgress: React.FC<IProps> = ({ label, value, className }) => {
  const classes = styles()

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <div className={classes.label}>{label}</div>
      <div className={classes.slider}>
        <div className={classes.sliderRail}>
          <Tooltip
            title={<span>{value > 0 ? value : 0}%</span>}
            open={true}
            overlayClassName={'tooltip'}
            fresh
            getPopupContainer={(trigger) => trigger}
            autoAdjustOverflow={false}
            placement="top"
          >
            <div
              className={classes.sliderPoint}
              style={{
                left: `${value > 0 ? value : 0}%`,
                backgroundColor: value > 0 ? VARIABLES.white : VARIABLES.bg,
                borderColor: value > 0 ? VARIABLES.green : VARIABLES.text_grey,
              }}
            ></div>
          </Tooltip>

          <div
            className={classes.sliderBar}
            style={{ width: `${+value > 0 ? value : 0}%` }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default AssetProgress
