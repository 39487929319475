import React from 'react'
import { createUseStyles } from 'react-jss'

import { VARIABLES } from 'src/constants/variables'

import { ReactComponent as SaleIcon } from '../../img/sale.svg'
import moment from 'moment'

const styles = createUseStyles({
  wrapper: {
    background: VARIABLES.purple,
    border: `1px solid ${VARIABLES.green}`,
    borderRadius: 8,
    padding: [12, 16],
    marginTop: 20,
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  iconContainer: {
    flexShrink: 0,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 40,
    height: 40,
  },
  title: {
    fontSize: 18,
    letterSpacing: 0.18,
    fontWeight: 500,
    lineHeight: '150%',
    color: VARIABLES.white,
    margin: 0,
    '& span': {
      color: VARIABLES.green,
    },
  },
  description: {
    fontSize: 16,
    letterSpacing: 0.16,
    color: VARIABLES.text_grey,
    fontWeight: 500,
    lineHeight: '150%',
    margin: 0,
  },
  mobile: {
    display: 'none',
  },

  [VARIABLES.tablet]: {},
  [VARIABLES.mobile]: {
    wrapper: {
      width: '100%',
    },
    iconContainer: {
      display: 'none',
    },
    title: {
      fontSize: 16,
    },
    description: {
      fontSize: 14,
    },
    mobile: {
      display: 'block',
    },
  },
})

interface IProps {
  discount: number
  finish: number
}

const WhitelistNotification: React.FC<IProps> = ({
  discount = 0,
  finish = 0,
}) => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.iconContainer}>
        <SaleIcon className={classes.icon} />
      </div>
      <div>
        <p className={classes.title}>
          You are whitelisted! Don't miss out on buying NFT with a{' '}
          <span>{discount}% discount</span>
        </p>
        <p className={classes.description}>
          Discount is active until <br className={classes.mobile} />{' '}
          {moment.unix(finish).utc().format('MMMM DD HH:mm [GMT]')}
        </p>
      </div>
    </div>
  )
}

export default WhitelistNotification
