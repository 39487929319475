const COLORS = {
  bg: '#1E143B',
  bg_dark: '#150E2B',
  primary: '#5C519E',
  primary_hover: '#7268AA',
  primary_active: '#514889',
  white: '#FFFFFF',
  green: '#00BE75',
  grey: 'rgba(203, 203, 248, 0.50)',
  grey_light: '#B9B9EB',
  bg_grey: '#68688B',
  text_grey: '#CBCBF8',
  card_bg: 'rgba(255, 255, 255, 0.03)',
  card_bg_dark: '#272147',
  card_border_color: 'rgba(255, 255, 255, 0.08)',
  pink: '#E2006E',
  purple: '#2A204A',
  blue: '#2E61B4',
  blue_secondary: '#3396FF',
  yellow: '#E48731',
  red: '#D5393C',
  grey_lighter: '#EBEBEB',
  backdrop: '#1E143BE5',
}

const SPACING = {
  desktop_container: 1360,
  desktop_header_height: 76,
  mobile_header_height: 60,
}

const TRANSITIONS = {
  element_transition: 'all 0.2s ease',
}

const BLUR = 'none'

const BREAKPOINTS = {
  tablet: '@media (max-width: 1023px)',
  mobile: '@media (max-width: 767px)',
}

export const VARIABLES = {
  ...COLORS,
  ...SPACING,
  ...TRANSITIONS,
  ...BREAKPOINTS,
  BLUR,
  fontFamily: 'DM Sans',
}
