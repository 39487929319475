import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'

import gradienImg from '../../img/cas_gradient.png'
import img_1 from '../../img/games/1.png'
import img_2 from '../../img/games/2.png'
import img_3 from '../../img/games/3.png'
import img_4 from '../../img/games/4.png'
import img_5 from '../../img/games/5.png'
import img_6 from '../../img/games/6.png'
import img_7 from '../../img/games/7.png'
import img_8 from '../../img/games/8.png'
import img_9 from '../../img/games/9.png'
import img_10 from '../../img/games/10.png'
import useResize from 'src/hooks/useResize'

const styles = createUseStyles({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 704,
  },
  grids: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '88%',
    marginTop: 35,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    position: 'relative',
    justifyContent: 'center',
    justifyItems: 'center',
    zIndex: 1,
    gap: 30,
  },
  card: {
    width: 150,
    height: 150,
    borderRadius: 10,
    overflow: 'hidden',
    gridColumn: 'span 2',

    '&:nth-last-child(1):nth-child(odd)': {
      gridColumn: '2 / span 2',
    },

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },

  content: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    zIndex: 4,
    marginTop: -10,
  },
  title: {
    position: 'absolute',
    fontSize: 56,
    fontWeight: 700,
    lineHeight: '120%',
    color: VARIABLES.white,
    margin: 0,
    letterSpacing: '1.7px',
    textAlign: 'center',
  },
  text: {
    marginTop: 16,
    marginBottom: 0,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    color: VARIABLES.text_grey,
  },
  placeholder: {
    backgroundColor: VARIABLES.card_bg_dark,
    border: `1px solid ${VARIABLES.card_border_color}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    aspectRatio: 27 / 16,
    maxWidth: 675,
  },
  icon: {
    color: VARIABLES.white,
    height: 24,
    width: 24,
  },

  gradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
  gradient_2: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    filter: 'blur(100px)',
    background:
      'radial-gradient(circle, rgba(30,20,59,0.8688068977591037) 50%, rgba(255,255,255,0) 100%);',
  },
  mobile_gradient_1: {
    display: 'none',
  },
  mobile_gradient_2: {
    display: 'none',
  },
  [VARIABLES.tablet]: {
    wrapper: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      marginTop: 107,
    },
    grids: {
      justifyContent: 'center',
      alignItems: 'center',
      gap: 0,
      width: '100%',
      marginTop: 40,
    },
    content: {
      position: 'relative',
      width: '100%',
    },
    title: {
      position: 'relative',
      fontSize: 42,
      fontWeight: 500,
      letterSpacing: '1.1px',
    },
    grid: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20,
    },
    card: {
      width: 120,
      height: 118,
    },
    placeholder: {
      width: '100%',
    },
    gradient: {
      display: 'none',
    },
    gradient_2: {
      display: 'none',
    },
    mobile_gradient_1: {
      display: 'block',
      height: '100%',
      width: 125,
      position: 'absolute',
      left: 0,
      top: 0,
      background: 'linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #1E143B 100%)',
      zIndex: 4,
    },
    mobile_gradient_2: {
      display: 'block',
      height: '100%',
      width: 125,
      position: 'absolute',
      right: 0,
      top: 0,
      background: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #1E143B 100%)',
      zIndex: 4,
    },
  },
  [VARIABLES.mobile]: {
    wrapper: {
      marginTop: 97,
    },
    title: {
      fontSize: 24,
      letterSpacing: 0.08,
      maxWidth: 250,
    },
    grids: {
      marginTop: 20,
    },
    grid: {
      gap: 20,
      rowGap: 20,
    },
    card: {
      width: 120,
      height: 120,
    },
    placeholder: {
      aspectRatio: 16 / 27,
      maxHeight: 400,
    },
  },
})

const ImagesBlock = () => {
  const classes = styles()
  const { isTablet } = useResize()

  return (
    <div className={classes.wrapper}>
      <img className={classes.gradient} src={gradienImg} alt="" />
      <div className={classes.content}>
        <p className={classes.title}>Explore Casino TYCOON NFT</p>
      </div>
      <div className={classes.gradient_2}></div>
      <div className={classes.mobile_gradient_1}></div>
      <div className={classes.mobile_gradient_2}></div>
      <div className={classes.grids}>
        {isTablet ? (
          <div className={classes.grid}>
            <div className={classes.card}>
              <img src={img_8} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_9} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_2} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_5} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_6} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_10} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_1} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_3} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_4} alt="" />
            </div>
            <div className={classes.card}>
              <img src={img_7} alt="" />
            </div>
          </div>
        ) : (
          <>
            <div className={classes.grid}>
              <div className={classes.card}>
                <img src={img_3} alt="" />
              </div>
              <div className={classes.card}>
                <img src={img_4} alt="" />
              </div>
              <div className={classes.card}>
                <img src={img_5} alt="" />
              </div>
              <div className={classes.card}>
                <img src={img_6} alt="" />
              </div>
              <div className={classes.card}>
                <img src={img_7} alt="" />
              </div>
            </div>
            <div className={classes.grid}>
              <div className={classes.card}>
                <img src={img_8} alt="" />
              </div>
              <div className={classes.card}>
                <img src={img_9} alt="" />
              </div>
              <div className={classes.card}>
                <img src={img_10} alt="" />
              </div>
              <div className={classes.card}>
                <img src={img_1} alt="" />
              </div>
              <div className={classes.card}>
                <img src={img_2} alt="" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ImagesBlock
