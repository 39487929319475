/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { request } from '../../factory/axios'
import { setMarketplaceNfts } from '../../redux/marketplace'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount } from 'wagmi'

import MyNFTSNoConnected from '../MyNFTSNoConnected/MyNFTSNoConnected'
import { VARIABLES } from 'src/constants/variables'

import NftCard from '../NFTCard/NFTCard'
import NftCardShimmer from '../NFTCard/NFTCardShimmer'

import Pagination from '../Pagination/Pagination'
import {
  INFTMarketplace,
  INFTMarketplaceResponse,
} from 'src/types/nft.interface'
import { CHAINS } from 'src/constants'
import Sorting from '../Sorting/Sorting'
import { IOption } from 'src/types/dropdown.interface'
import WhitelistNotification from '../WhitelistNotification/WhitelistNotification'
import BuyNFTModal from '../BuyNFTModal/BuyNFTModal'

const styles = createUseStyles({
  titleText: {
    fontWeight: 500,
    fontSize: 42,
    lineHeight: '120%',
    color: VARIABLES.white,
    letterSpacing: 2.24,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    zIndex: 10,
    position: 'relative',
  },
  mainBlock: {
    marginTop: 40,
    marginBottom: 60,
    position: 'relative',
    zIndex: 10,
  },
  nftCardWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(325px,1fr))',
    alignItems: 'center',
    gap: 20,
    paddingTop: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    justifyItems: 'flex-start',
    position: 'relative',
    zIndex: 5,
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24,
  },
  paginationText: {
    color: VARIABLES.text_grey,
    fontSize: 14,
    fontWeight: 500,
  },
  icon: {
    width: 20,
    height: 20,
  },
  nftCard: {
    minWidth: 'auto',
    width: '100%',
    aspectRatio: 325 / 489,
  },
  '@media (max-width: 1439px)': {
    nftCard: {
      aspectRatio: 'auto',
      minHeight: '100% !important',
      height: 'auto',
    },
  },
  [VARIABLES.tablet]: {
    mainBlock: {
      marginTop: 55,
      marginBottom: 0,
    },
    nftCardWrapper: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(325px,1fr))',
      gap: 40,
    },
    nftCard: {
      minWidth: 'auto',
      width: '100%',
      aspectRatio: 'auto',
      minHeight: '100% !important',
      height: 'auto',
    },
  },
  [VARIABLES.mobile]: {
    nftCard: {
      aspectRatio: 'auto',
      minHeight: '100% !important',
      height: 'auto',
    },
    titleWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    titleText: {
      fontSize: 42,
      marginBottom: 20,
    },
    mainBlock: {
      marginTop: 62,
    },
    icon: {
      width: 16,
      height: 16,
    },
    nftCardWrapper: {
      paddingTop: 20,
    },
    paginationWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 3,
      marginTop: 40,
    },
  },
})

interface Props {}
interface IPagination {
  page: number
  limit: number
  pages: number
  total: number
}

const sortingOptions: IOption[] = [
  {
    label: 'Price - Low to High',
    value: '1',
  },
  {
    label: 'Price - High to Low',
    value: '-1',
  },
]

const Marketplace: React.FC<Props> = () => {
  const classes = styles()

  const [loadingNFT, setLoadingNFT] = useState(true)
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    pages: 1,
    limit: 12,
    total: 0,
  })
  const [nftForBuy, setNftForBuy] = useState<INFTMarketplace | null>(null)
  const [isEligible, setIsEligible] = useState(false)
  const [currentSorting, setCurrentSorting] = useState<IOption>(
    sortingOptions[0]
  )
  const [discount, setDiscount] = useState(0)
  const [availableOnDiscount, setAvailableOnDiscount] = useState(0)
  const [finish, setFinish] = useState(0)

  const { address, chain } = useAccount()
  const { nft } = useSelector((state: any) => state.marketplaceReducer)

  const dispatch = useDispatch()
  const mobileScrollContainer = useRef<any>(null)

  useEffect(() => {
    fetchNFT()
  }, [address])

  useEffect(() => {
    fetchNFT(1, false, false)
  }, [currentSorting])

  const fetchNFT = async (
    page: number = 1,
    isLoadMore: boolean = false,
    withLoader: boolean = true
  ) => {
    if (withLoader) {
      setLoadingNFT(true)
    }

    await request({
      method: 'get',
      path: `sales/list?limit=${pagination.limit}&page=${page}&order=${
        currentSorting.value
      }${address ? `&address=${address}` : ''}`,
    })
      .then((req) => {
        const result: INFTMarketplaceResponse = req?.data?.data

        const nfts = result.entities

        isLoadMore
          ? dispatch(setMarketplaceNfts([...nft, ...nfts]))
          : dispatch(setMarketplaceNfts(nfts))

        setPagination({
          ...pagination,
          page,
          total: result.countItems,
          pages: result.pages,
        })
        setIsEligible(result.isEligible)
        setDiscount(result.discount)
        setFinish(result.finishTimestamp)
        setAvailableOnDiscount(result.availableOnDiscount)
      })
      .finally(() => {
        setLoadingNFT(false)
      })
      .catch((error: any) => console.log('Error fetching NFT:', error))
  }

  const handlePageChange = async ({ selected }: { selected: number }) => {
    if (selected + 1 === pagination.page) return
    await fetchNFT(selected + 1)

    document.getElementById('marketplace-container')?.scrollIntoView()
  }

  const handleSorting = (value: IOption) => {
    setCurrentSorting(value)
  }

  const handleCloseModal = () => {
    fetchNFT(pagination.page, false, false)
    setNftForBuy(null)
  }

  return (
    <>
      {nftForBuy && (
        <BuyNFTModal
          nft={nftForBuy}
          onClose={handleCloseModal}
          isDiscount={isEligible}
          discount={discount}
          availableOnDiscount={availableOnDiscount}
        />
      )}
      <div id="marketplace-container" className={classes.mainBlock}>
        <div className={classes.titleWrapper}>
          <div className={classes.titleText}>Marketplace</div>
          <Sorting options={sortingOptions} onChange={handleSorting} />
        </div>
        {isEligible && (
          <WhitelistNotification discount={discount} finish={finish} />
        )}

        {loadingNFT ? (
          <div className={classes.nftCardWrapper}>
            {Array.from(Array(pagination.limit).keys()).map((key) => (
              <NftCardShimmer key={key} />
            ))}
          </div>
        ) : nft.length ? (
          <div ref={mobileScrollContainer} className={classes.nftCardWrapper}>
            {nft.map((item: INFTMarketplace, index: number) => (
              <NftCard
                forMarketplace={true}
                // @ts-ignore
                data={item as INFTMarketplace}
                key={item._id}
                isWrongNetwork={chain?.id !== CHAINS[0].id}
                className={classes.nftCard}
                onBuy={() => setNftForBuy(item)}
                isDiscount={isEligible}
                discount={discount}
              />
            ))}
          </div>
        ) : (
          <MyNFTSNoConnected />
        )}
        {nft.length ? (
          <div className={classes.paginationWrapper}>
            <div className={classes.paginationText}>
              Showing{' '}
              {pagination.page * pagination.limit - pagination.limit + 1} -{' '}
              {pagination.page * pagination.limit > pagination.total
                ? pagination.total
                : pagination.page * pagination.limit}{' '}
              out of {pagination.total}
            </div>
            {pagination.pages > 1 && (
              <div>
                <Pagination
                  pageCount={pagination.pages}
                  initialPage={pagination.page - 1}
                  onPageChange={handlePageChange}
                  forcePage={pagination.page - 1}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </>
  )
}
export default Marketplace
