import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'

import { ReactComponent as ArrowIcon } from '../../img/arrow.svg'
import { buttonStyleWhite } from 'src/styles/button'

// import desktopVideoMp4 from '../../assets/banner/video_1024.mp4'
import desktopVideoWebm from '../../assets/banner/video.webm'

import mobileImage from '../../assets/banner/mobile_image_2.png'
//@ts-ignore
import tabletImage from '../../assets/banner/mobile_image.jpg'

import useResize from 'src/hooks/useResize'

const styles = createUseStyles({
  banner: {
    width: '100%',
    height: 600,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    color: VARIABLES.white,
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
    zIndex: 2,
    padding: 40,
    maxWidth: 470,
  },
  object: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1,

    '& img, & video, & picture': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },

  title: {
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '120%',
    margin: 0,
    letterSpacing: 2.3,
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
    margin: [20, 0, 0, 0],
  },
  button: {
    ...buttonStyleWhite,
    height: 48,
    padding: [0, 24],
    marginTop: 40,
    cursor: 'pointer',
    userSelect: 'all',
    pointerEvents: 'all',
    width: 'max-content',
  },
  buttonIcon: {
    width: 16,
    height: 16,
    transform: 'rotate(-45deg)',
  },
  [VARIABLES.tablet]: {
    banner: {
      height: 740,
    },
    container: {
      padding: [10, 20, 20],
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      marginTop: 5,
    },
    button: {
      marginTop: 30,
      width: 'max-content',
    },
  },
  [VARIABLES.mobile]: {
    banner: {
      height: 580,
    },

    object: {
      display: 'flex',
      justifyContent: 'center',
      '& video': {
        display: 'none',
      },
      '& img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center bottom',
      },
    },
    text: {
      marginTop: 10,
    },
    container: {
      padding: [10, 20, 20],
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: 28,
      fontWeight: 400,
      lineHeight: '120%',
      letterSpacing: 0.28,
    },
    buttonIcon: {
      display: 'none',
    },
  },
})

const Banner = () => {
  const { isMobile } = useResize()

  const classes = styles()

  return (
    <div className={classes.banner}>
      <div className={classes.gradient}></div>
      <div className={classes.object}>
        {isMobile ? (
          <picture>
            <source srcSet={mobileImage} media="(max-width: 600px)" />
            <source srcSet={tabletImage} media="(min-width: 601px)" />
            <img src={mobileImage} alt="mobile" />
          </picture>
        ) : (
          <video
            autoPlay
            loop
            controls={false}
            playsInline
            muted
            preload="auto"
          >
            <source src={desktopVideoWebm} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className={classes.container}>
        <h1 className={classes.title}>
          Be an owner <br /> Not just a player
        </h1>
        <p className={classes.text}>
          A one-of-a-kind revolutionary NFT platform that allows users to stake
          casino related NFTs and earn rewards from casino profits.
        </p>
        <a
          href="https://twitter.com/casino_tycoon"
          target="_blank"
          rel="noreferrer"
          className={classes.button}
        >
          Explore
          <ArrowIcon className={classes.buttonIcon} />
        </a>
      </div>
    </div>
  )
}

export default Banner
