import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'

import roadmapImg from '../../img/roadmap.png'
import roadmapImgTablet from '../../img/roadmap_tablet.png'
import roadmapImgMobile from '../../img/roadmap_mobile.png'
import useResize from 'src/hooks/useResize'

const styles = createUseStyles({
  wrapper: {
    marginTop: 160,
    marginBottom: 150,
  },
  container: {
    maxWidth: 1313,
    margin: '0 auto',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
  },
  title: {
    display: 'none',
  },

  '@media (max-width: 959px)': {
    wrapper: {
      marginTop: 60,
      marginBottom: 0,
    },
    container: {
      width: 398,
      margin: 0,
    },
    title: {
      display: 'block',
      fontSize: 42,
      color: VARIABLES.white,
      fontWeight: 500,
      lineHeight: '120%',
      marginBottom: 40,
    },
  },
  [VARIABLES.mobile]: {
    container: {
      width: 232,
    },
    title: {
      fontSize: 24,
    },
  },
})
const Roadmap = () => {
  const classes = styles()
  const { isTablet, isMobile, currentWidth } = useResize()

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.title}>How does it work?</div>
        <img
          className={classes.img}
          src={
            currentWidth < 768
              ? roadmapImgMobile
              : currentWidth < 960
              ? roadmapImgTablet
              : roadmapImg
          }
          alt="roadmap"
        />
      </div>
    </div>
  )
}

export default Roadmap
