import React from 'react'
import { createUseStyles } from 'react-jss'

import { ReactComponent as SuccessIcon } from '../../img/success.svg'
import { ReactComponent as InfoIcon } from '../../img/info.svg'
import { ReactComponent as WarningIcon } from '../../img/warning.svg'
import { ReactComponent as ErrorIcon } from '../../img/error.svg'
import { ReactComponent as CloseIcon } from '../../img/close.svg'

import { VARIABLES } from 'src/constants/variables'

import classNames from 'classnames'
import toast from 'react-hot-toast'

const styles = createUseStyles({
  body: {
    backgroundColor: VARIABLES.white,
    width: 400,
    borderRadius: 8,
    border: `1px solid ${VARIABLES.card_border_color}`,
    display: 'flex',
    padding: [14, 16, 16, 16],
    boxSizing: 'border-box',
    gap: 8,
    position: 'relative',
  },
  'body--success': {
    color: VARIABLES.green,
  },
  'body--error': {
    color: VARIABLES.red,
  },
  'body--warning': {
    color: VARIABLES.yellow,
  },
  'body--info': {
    color: VARIABLES.blue,
  },

  iconWrapper: {
    width: 16,
    height: 16,
    marginTop: 2,
  },
  icon: {
    width: 16,
    height: 16,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  title: {
    margin: 0,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.16,
    lineHeight: 'normal',
  },
  text: {
    margin: 0,
    color: VARIABLES.bg,
    fontSize: 12,
    lineHeight: '150%',
    fontWeight: 400,
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    paddingRight: 20,

    '& a': {
      color: VARIABLES.pink,
      fontSize: 12,
      lineHeight: '150%',
      fontWeight: 400,
    },
  },
  closeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    color: VARIABLES.bg,
    background: 'transparent',
    position: 'absolute',
    right: 15,
    top: 15,
    margin: 0,
    padding: 0,
    cursor: 'pointer',
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
})

interface IProps {
  title?: string
  text?: React.ReactNode
  type: 'success' | 'error' | 'warning' | 'info'
  id: string
}

const ToastBody: React.FC<IProps> = ({
  type = 'info',
  title = '',
  text = '',
  id,
}) => {
  const classes = styles()

  const toastIcon = () => {
    switch (type) {
      case 'success':
        return <SuccessIcon />
      case 'error':
        return <ErrorIcon />
      case 'warning':
        return <WarningIcon />
      case 'info':
        return <InfoIcon />
    }
  }

  const closeToast = () => {
    toast.dismiss(id)
  }

  return (
    <div className={classNames(classes.body, classes[`body--${type}`])}>
      <button className={classes.closeButton} onClick={closeToast}>
        <CloseIcon className={classes.closeIcon} />
      </button>
      <div className={classes.iconWrapper}>{toastIcon()}</div>
      <div className={classes.content}>
        {title && <p className={classes.title}>{title}</p>}
        {text && <p className={classes.text}>{text}</p>}
      </div>
    </div>
  )
}

export default ToastBody
