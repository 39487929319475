import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'
import AssetProgress from '../AssetProgress/AssetProgress'
import moment from 'moment'

import { useTimer } from 'react-timer-hook'

const styles = createUseStyles({
  wrapper: {
    borderRadius: 8,
    border: `1px solid ${VARIABLES.card_border_color}`,
    background: VARIABLES.card_bg,
    padding: [25, 20, 30, 20],
    position: 'relative',
    zIndex: 5,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '110%',
    letterSpacing: 0.24,
    color: VARIABLES.white,
    margin: 0,
  },
  date: {
    color: VARIABLES.text_grey,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.12,
    margin: 0,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 4,
    marginBottom: 4,
    marginTop: 32,
    filter: VARIABLES.BLUR,
  },
  blur: {
    filter: VARIABLES.BLUR,
  },
  [VARIABLES.tablet]: {
    grid: {
      gridTemplateColumns: '100%',
      gap: 22,
      marginBottom: 22,
    },
  },
  [VARIABLES.mobile]: {
    wrapper: {
      padding: [25, 16, 30, 16],
    },
  },
})

const Statistics = () => {
  const classes = styles()
  const todayFormatted = moment().format('DD/MM/YYYY')

  const getDaysInMonth = () => {
    return moment().daysInMonth()
  }

  const getExpiryTimestamp = () => {
    return moment().endOf('month').toDate()
  }

  let daysInMonth = getDaysInMonth()
  let expiryTimestamp = getExpiryTimestamp()

  const restartTimer = () => {
    const newDaysInMonth = getDaysInMonth()
    const newExpiryTimestamp = getExpiryTimestamp()

    daysInMonth = newDaysInMonth
    expiryTimestamp = newExpiryTimestamp

    setTimeout(() => {
      restart(newExpiryTimestamp)
    }, 1000)
  }

  const { days, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => restartTimer(),
  })

  const currentDay = daysInMonth - days
  const first_decade = 10
  const second_decade = 10
  const third_decade = daysInMonth - first_decade - second_decade

  const calculatePNL = (day: number, decade: number): number => {
    let result = (day * 100) / decade || 0

    if (result > 100) {
      result = 100
    }

    if (result < 0) {
      result = 0
    }
    return Math.floor(result)
  }

  const calculateRewards = (): number => {
    let result = Math.round((currentDay * 100) / daysInMonth)

    if (result > 100) {
      result = 100
    }

    if (result < 0) {
      result = 0
    }

    return result
  }

  // PNL 1
  const first_decade_progress = calculatePNL(currentDay, first_decade)

  // PNL 2
  const second_decade_progress = calculatePNL(
    currentDay - first_decade,
    second_decade
  )

  // PNL 3
  const third_decade_progress = calculatePNL(
    currentDay - first_decade - second_decade,
    third_decade
  )

  // Rewards
  const rewards_progress = calculateRewards()

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <p className={classes.title}>Statistics</p>
        <p className={classes.date}>Today: {todayFormatted}</p>
      </div>
      <div className={classes.grid}>
        <AssetProgress label="PNL 1" value={first_decade_progress} />
        <AssetProgress label="PNL 2" value={second_decade_progress} />
        <AssetProgress label="PNL 3" value={third_decade_progress} />
      </div>
      <AssetProgress label="Rewards" value={rewards_progress} />
    </div>
  )
}

export default Statistics
