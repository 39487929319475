import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { VARIABLES } from 'src/constants/variables'

const styles = createUseStyles({
  badge: {
    padding: [0, 8],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: VARIABLES.white,
    backgroundColor: VARIABLES.green,
    height: 20,
    borderRadius: 24,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.12,
    textAlign: 'center',
    margin: 0,
    lineHeight: '1',
  },
  disabled: {
    backgroundColor: VARIABLES.text_grey,
    color: VARIABLES.purple,
  },
})

interface IProps {
  disabled?: boolean
  value: string
}

const Badge: React.FC<IProps> = ({ disabled = false, value = '' }) => {
  const classes = styles()

  return (
    <div className={classNames(classes.badge, disabled && classes.disabled)}>
      {value}
    </div>
  )
}

export default Badge
