import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'

import { ReactComponent as ArrowIcon } from '../../img/arrow.svg'

const styles = createUseStyles({
  wrapper: {
    maxWidth: 832,
    margin: '0 auto',
  },
  back: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '150%',
    color: VARIABLES.white,
    textDecoration: 'none',
    fontFamily: VARIABLES.fontFamily,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    transition: VARIABLES.element_transition,
    width: 'max-content',

    '&:hover': {
      color: VARIABLES.text_grey,
    },
  },
  backIcon: {
    width: 16,
    height: 16,
    transform: 'rotate(180deg)',
  },
  content: {
    marginTop: 40,
    color: VARIABLES.text_grey,

    '& h1': {
      color: VARIABLES.white,
      fontSize: 42,
      fontWeight: 500,
      lineHeight: '120%',
      margin: 0,
      marginBottom: 16,
      letterSpacing: 2.24,
    },
    '& h2': {
      margin: 0,
      color: VARIABLES.white,
      fontSize: 32,
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: 1.2,

      '&:not(:first-child)': {
        marginTop: 20,
      },
    },
    '& div': {
      marginTop: 60,
    },
    '& p': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '150%',
      margin: 0,
      marginBottom: 30,
    },
    '& li': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '150%',
      margin: 0,
      marginBottom: 10,
    },
    '& ol': {
      padding: 0,
    },
    '& ol li': {
      listStyle: 'none',
    },
    '& ul': {
      marginTop: 10,
      paddingLeft: 23,
    },
    '& ul li': {
      marginBottom: 0,
      listStyle: 'disc',
    },
    '& a': {
      color: VARIABLES.pink,
      textDecoration: 'none',
    },
  },
  [VARIABLES.tablet]: {
    content: {
      marginTop: 60,
    },
  },
  [VARIABLES.mobile]: {
    content: {
      marginTop: 40,
    },
  },
})

interface IProps {
  children: React.ReactNode
}

const DocumentContent: React.FC<IProps> = ({ children }) => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <a href="/" className={classes.back}>
        <ArrowIcon className={classes.backIcon} /> Back to Home Page
      </a>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default DocumentContent
