import { configureStore } from '@reduxjs/toolkit'
import loadingSlice from './loading'
import userNftsSlice from './userNfts'
import marketplaceSlice from './marketplace'

export const store = configureStore({
  reducer: {
    loadingReducer: loadingSlice,
    userNftsReducer: userNftsSlice,
    marketplaceReducer: marketplaceSlice,
  },
})
