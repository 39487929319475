export function commify(value: string) {
  const splitedValue = value.split('.')
  let parts2 = ''
  const parts = splitedValue[0].replace(/[\s,]/g, '')

  const thousands = /\B(?=(\d{3})+(?!\d))/g
  if (splitedValue.length > 1) {
    parts2 = `.<span>${splitedValue[1]}</span>`
  }

  return parts.replace(thousands, ',') + parts2
}

export function numberToStringInput(num: number) {
  let numStr = String(num)

  if (Math.abs(num) < 1.0) {
    let e = parseInt(num.toString().split('e-')[1])
    if (e) {
      let negative = num < 0
      if (negative) num *= -1
      num *= Math.pow(10, e - 1)
      numStr = '0.' + new Array(e).join('0') + num.toString().substring(2)
      if (negative) numStr = '-' + numStr
    }
  } else {
    let e = parseInt(num.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      num /= Math.pow(10, e)
      numStr = num.toString() + new Array(e + 1).join('0')
    }
  }

  return numStr
}

export const truncate = (
  fullStr: any,
  strLength: number,
  separator?: string
): string => {
  if (fullStr.length <= strLength) return fullStr

  separator = separator || '...'

  let sepLen = separator.length,
    charsToShow = strLength - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2)

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  )
}

export const getIPFSImage = (url: string) => {
  return url.replace('ipfs://', 'https://ipfs.io/ipfs/')
}
