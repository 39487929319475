import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'
import { buttonStyle } from 'src/styles/button'
import { ReactComponent as CloseIcon } from '../../img/close.svg'
import { CHAINS } from 'src/constants'
import { useSwitchChain } from 'wagmi'

const styles = createUseStyles({
  modalBg: {
    width: '100vw',
    background: VARIABLES.backdrop,
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrongNetworkModal: {
    background: VARIABLES.purple,
    borderRadius: 8,
    padding: [30, 20, 20],
    width: 360,
    position: 'relative',
    boxSizing: 'border-box',
  },
  titleWrapper: {
    display: 'flex',
    paddingBottom: 10,
    justifyContent: 'space-between',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '110%',
    letterSpacing: 0.24,
    color: VARIABLES.white,
  },
  infoText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    color: VARIABLES.text_grey,
    paddingBottom: 30,
    maxWidth: 300,
  },
  linkText: {
    extend: 'infoText',
    color: VARIABLES.blue_secondary,
    textDecoration: 'underline',
    cursor: 'pointer',
    background: 'transparent',
    margin: 0,
    padding: 0,
    paddingBottom: 0,
    fontFamily: VARIABLES.fontFamily,
  },
  button: {
    ...buttonStyle,
    padding: [0, 12],
    width: '100%',
    height: 48,
    cursor: 'pointer',
    pointerEvents: 'all',
  },
  closeButton: {
    position: 'absolute',
    top: 32,
    right: 14,
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: VARIABLES.white,
    cursor: 'pointer',
    transition: VARIABLES.element_transition,

    '&:hover': {
      color: VARIABLES.text_grey,
    },
  },
  closeIcon: {
    width: 20,
    height: 20,
  },
  [VARIABLES.mobile]: {
    wrongNetworkModal: {
      width: 320,
    },
  },
})

interface Props {
  isNftPage?: boolean
  closeModal: () => void
}

const WrongNetworkModal: React.FC<Props> = ({ closeModal }) => {
  const classes = styles()
  const { switchChain: switchNetwork } = useSwitchChain()

  const handleSwitchNetwork = async () => {
    const body = document.body
    body.style.height = ''
    body.style.overflowY = ''

    try {
      await switchNetwork({
        chainId: CHAINS[0].id,
      })
    } catch (e) {}
  }

  return (
    <div className={classes.modalBg} onClick={closeModal}>
      <div
        className={classes.wrongNetworkModal}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={classes.closeButton} onClick={closeModal}>
          <CloseIcon className={classes.closeIcon} />
        </button>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Wrong Network</div>
        </div>
        <div className={classes.infoText}>
          Hello friend, you appear to be one the wrong network. To use{' '}
          {CHAINS[0].name}, you’ll need to switch to the{' '}
          <button
            className={classes.linkText}
            onClick={() => handleSwitchNetwork()}
          >
            {CHAINS[0].name} Network
          </button>
        </div>
        <button
          onClick={() => handleSwitchNetwork()}
          className={classes.button}
        >
          Switch Network
        </button>
      </div>
    </div>
  )
}

export default WrongNetworkModal
