import React from 'react'
import { ReactComponent as SupportIcon } from '../../img/support.svg'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'
import { buttonStyle } from 'src/styles/button'

const styles = createUseStyles({
  wrapper: {
    position: 'sticky',
    bottom: 40,
    margin: [0, 'auto'],
    marginBottom: 40,
    width: '100%',
    height: 48,
    zIndex: 9997,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: [0, 40],
    pointerEvents: 'none',
  },
  container: {
    maxWidth: VARIABLES.desktop_container,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    pointerEvents: 'none',
  },
  button: {
    ...buttonStyle,
    height: 48,
    backgroundColor: VARIABLES.purple,
    display: 'flex',
    padding: [12, 20],
    borderRadius: 4,
    boxSizing: 'border-box',
    pointerEvents: 'all',
    gap: 0,
  },
  icon: {
    width: 24,
    height: 24,
    color: VARIABLES.text_grey,
    marginRight: 12,
  },
  text: {
    color: 'white',
    marginRight: 4,
  },
  textSecondary: {
    color: VARIABLES.text_grey,
  },

  [VARIABLES.tablet]: {
    wrapper: {
      padding: [0, 30],
    },
  },
  [VARIABLES.mobile]: {
    wrappper: {
      padding: [0, 20],
    },
    container: {
      justifyContent: 'center',
    },
  },
})

const SupportButton = () => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <a href="mailto:support@casinotycoon.io" className={classes.button}>
          <SupportIcon className={classes.icon} />
          <span className={classes.text}>Need Help?</span>
          <span className={classes.textSecondary}>Write us</span>
        </a>
      </div>
    </div>
  )
}
export default SupportButton
