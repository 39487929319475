import React, { ReactNode } from 'react'
import toast from 'react-hot-toast'
import ToastBody from 'src/components/ToastBody/ToastBody'

interface IProps {
  title: string
  text: ReactNode
  id?: any
}

export default function useToast() {
  const notification = {
    success: ({ title, text, id }: IProps) => {
      toast.custom(
        (t) => (
          <ToastBody
            text={text || ''}
            title={title || ''}
            type="success"
            id={id || t.id}
          />
        ),
        {
          duration: 10000,
        }
      )
    },
    error: ({ title, text, id }: IProps) => {
      toast.custom(
        (t) => (
          <ToastBody
            text={text || ''}
            title={title || ''}
            type="error"
            id={id || t.id}
          />
        ),
        {
          duration: 10000,
        }
      )
    },
    info: ({ title, text, id }: IProps) => {
      toast.custom(
        (t) => (
          <ToastBody
            text={text || ''}
            title={title || ''}
            type="info"
            id={id || t.id}
          />
        ),
        {
          duration: 10000,
        }
      )
    },
  }

  return { notification }
}
