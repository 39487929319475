import React from 'react'
import { createUseStyles } from 'react-jss'
import { VARIABLES } from 'src/constants/variables'

const styles = createUseStyles({
  '@keyframes shimmerAnimation': {
    to: {
      '-webkit-mask-position': 'left',
    },
  },
  shimmer: {
    '-webkit-mask':
      'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%',
    animation: '$shimmerAnimation 2.5s infinite',
  },

  mainWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 10,
    padding: 16,
    background: 'rgba(255, 255, 255, 0.02)',
    border: `1px solid ${VARIABLES.card_border_color}`,
    borderRadius: 8,
    minWidth: 325,
    flexBasis: 325,
    width: '100%',
    gap: 18,
    height: '100%',
    boxSizing: 'border-box',
    minHeight: 549,
  },
  imgContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: 260,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 260,
    border: `1px solid ${VARIABLES.card_border_color}`,
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  nftIndex: {
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    borderRadius: 30,
    lineHeight: 'normal',
    height: 28,
    width: 76,
    extend: 'shimmer',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '148%',
    color: VARIABLES.text_grey,
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    borderRadius: 8,
    height: 16,
    width: 93,
    extend: 'shimmer',
  },
  stakeButton: {
    height: 48,
    padding: [0, 24],
    width: '100%',
    minHeight: 48,
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    borderRadius: 30,
    boxSizing: 'border-box',
    extend: 'shimmer',
  },
  [VARIABLES.tablet]: {
    mainWrapper: {
      minWidth: 325,
      flexBasis: 325,
      width: 325,
    },
  },
  [VARIABLES.mobile]: {
    mainWrapper: {
      width: 280,
      minWidth: 280,
      flexBasis: 280,
      minHeight: 517,
    },
    imgContainer: {
      minHeight: 228,
    },
  },
})

const NftCardShimmer = () => {
  const classes = styles()

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.imgContainer}>
        <div className={`${classes.nftIndex}`}></div>
      </div>
      <div className={classes.header}>
        <div className={classes.text} style={{ width: 93 }}></div>
        <div className={classes.text} style={{ width: 70 }}></div>
      </div>
      <div className={classes.content}>
        <div className={classes.row}>
          <div className={classes.text} style={{ width: 91 }}></div>
          <div className={classes.text} style={{ width: 89 }}></div>
        </div>
        <div className={classes.row}>
          <div className={classes.text} style={{ width: 53 }}></div>
          <div className={classes.text} style={{ width: 56 }}></div>
        </div>
        <div className={classes.row}>
          <div className={classes.text} style={{ width: 41 }}></div>
          <div className={classes.text} style={{ width: 46 }}></div>
        </div>
        <div className={classes.row}>
          <div className={classes.text} style={{ width: 48 }}></div>
          <div className={classes.text} style={{ width: 62 }}></div>
        </div>
        <div className={classes.row}>
          <div className={classes.text} style={{ width: 72 }}></div>
          <div className={classes.text} style={{ width: 71 }}></div>
        </div>
      </div>
      <div className={classes.stakeButton}></div>
    </div>
  )
}

export default NftCardShimmer
