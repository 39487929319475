import React from 'react'
import { createUseStyles } from 'react-jss'
import { ReactComponent as StakingIcon } from '../../img/NFT/green-stake.svg'
import { ReactComponent as CoinIcon } from '../../img/NFT/coin.svg'
import { VARIABLES } from 'src/constants/variables'
import { IStaking } from 'src/types/staking.interface'

const styles = createUseStyles({
  mainBlock: {
    background: VARIABLES.card_bg,
    border: `1px solid ${VARIABLES.card_border_color}`,
    borderRadius: 8,
    padding: [25, 20, 30, 20],
    display: 'block',
    width: '100%',
    position: 'relative',
    zIndex: 5,
    boxSizing: 'border-box',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 25,
  },
  titleText: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '110%',
    letterSpacing: 0.24,
    color: VARIABLES.white,
  },
  card: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 12,
  },
  iconWrapper: {
    borderRadius: '50%',
    width: 54,
    height: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
  },
  cardWrapper: {
    display: 'flex',
    gap: 45,
    filter: VARIABLES.BLUR,
  },
  subText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '150%',
    color: VARIABLES.grey_light,
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: 5,
  },
  img: {
    width: 24,
    height: 24,
    color: VARIABLES.white,
  },
  mainText: {
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '110%',
    color: VARIABLES.white,
    letterSpacing: 0.2,
  },
  infoIcon: {
    color: VARIABLES.text_grey,
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  blur: {
    filter: VARIABLES.BLUR,
  },
  [VARIABLES.tablet]: {
    mainBlock: {
      padding: [23, 20, 30, 20],
    },
  },
  [VARIABLES.mobile]: {
    mainBlock: {
      padding: [23, 16, 25, 16],
    },
    cardWrapper: {
      flexDirection: 'column',
      gap: 20,
    },
    titleWrapper: {
      paddingBottom: 20,
    },
    mainText: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '19.8px',
      letterSpacing: '1%',
    },
  },
})

interface Props {
  staking: IStaking | null
}

const StakingOverview: React.FC<Props> = ({ staking }) => {
  const classes = styles()

  return (
    <div className={classes.mainBlock}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleText}>Staking Overview </div>
      </div>
      <div className={classes.cardWrapper}>
        <div className={classes.card}>
          <div className={classes.iconWrapper}>
            <StakingIcon className={classes.img} />
          </div>
          <div className={classes.textWrapper}>
            <div className={classes.subText}>Staked/Available</div>
            <div className={classes.mainText}>
              {/* 0 / 1000 */}
              {staking?.totalStaked || 0} / {staking?.total || 0}
            </div>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.iconWrapper}>
            <CoinIcon className={classes.img} />
          </div>
          <div className={classes.textWrapper}>
            <div className={classes.subText}>Percent staked</div>

            <div className={classes.mainText}>
              {staking?.ratio || 0}%
              {/* 0% */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StakingOverview
