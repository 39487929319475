import axios from 'axios'
import { store } from '../redux/store'
import { setLoading } from '../redux/loading'

interface params {
  method: string
  path: string
  data?: {}
}
interface paramsNFT {
  method: string
  url: string
  data?: {}
}

export const request = ({ method, path, data }: params) => {
  return axios({
    method,
    url: `${process.env.REACT_APP_X_API_URL}/${path}`,
    data,
  })
    .then((res) => res)
    .finally(() => {
      setTimeout(() => store.dispatch(setLoading(false)), 1000)
    })
}

export const requestNFT = ({ method, url, data, signal = null }: paramsNFT) => {
  return axios({
    method,
    url,
    signal,
    data,
  })
    .then((res) => res)
    .finally(() => {
      setTimeout(() => store.dispatch(setLoading(false)), 1000)
    })
}
