import React from 'react'
import { createUseStyles } from 'react-jss'
import { ReactComponent as ChevronIcon } from '../../img/chevron.svg'
import useComponentVisible from '../../hooks/useComponentVisible'

import { VARIABLES } from 'src/constants/variables'
import { IOption } from 'src/types/dropdown.interface'

const styles = createUseStyles({
  wrapper: {
    position: 'relative',
    zIndex: 100,
  },
  container: {
    boxSizing: 'border-box',
    padding: [0, 12],
    height: 36,
    borderRadius: 4,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'transparent',
    gap: 8,
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    transition: VARIABLES.element_transition,
    color: VARIABLES.white,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.10)',
    },

    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 0.10)',
    },
  },
  text: {
    fontFamily: VARIABLES.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 'normal',
    color: VARIABLES.white,
  },
  buttonName: {
    extend: 'text',
    userSelect: 'none',
    '& span': {
      color: VARIABLES.text_grey,
      marginRight: 8,
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  dropdown: {
    boxSizing: 'border-box',
    minWidth: 124,
    background: VARIABLES.purple,
    position: 'absolute',
    boxShadow: `0px 2px 2px ${VARIABLES.bg_dark}`,
    border: `1px solid ${VARIABLES.card_border_color}`,
    borderRadius: 4,
    top: 'calc(100% + 4px)',
    padding: 4,
    right: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 0,
    userSelect: 'none',
  },
  dropdownItem: {
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    gap: 10,
    padding: 8,
    cursor: 'pointer',
    transition: VARIABLES.element_transition,
    height: 48,

    '&:hover': {
      backgroundColor: VARIABLES.card_bg,
    },
  },
  icon: {
    width: 16,
    height: 16,
  },
  arrow: {
    transition: VARIABLES.element_transition,
  },
  arrowActive: {
    transform: 'rotate(180deg)',
  },

  [VARIABLES.mobile]: {
    wrapper: {
      width: '100%',
    },
    container: {
      minWidth: 'auto',
      padding: [0, 12],
    },
  },
})

interface IProps {
  options: IOption[]
  onChange?: (option: IOption) => void
}

const Sorting: React.FC<IProps> = ({ options, onChange }) => {
  const [currentValue, setCurrentValue] = React.useState<IOption>(options[0])
  const classes = styles()

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const handleOpen = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  const selectValue = (option: IOption) => {
    setCurrentValue(option)
    onChange && onChange(option)
    setIsComponentVisible(false)
  }

  return (
    <>
      <div className={classes.wrapper} ref={ref}>
        <div className={classes.container} onClick={() => handleOpen()}>
          <div className={classes.iconWrapper}>
            <div className={classes.buttonName}>
              <span>Sort by: </span> {currentValue.label}
            </div>
          </div>
          <ChevronIcon
            className={`${classes.arrow} ${
              isComponentVisible && classes.arrowActive
            }`}
          />
        </div>
        {isComponentVisible ? (
          <div className={classes.dropdown}>
            {options.map((option: IOption) => {
              return (
                <div
                  className={classes.dropdownItem}
                  key={option.value}
                  onClick={() => selectValue(option)}
                >
                  <div className={classes.text}>{option.label}</div>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default Sorting
