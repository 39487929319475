import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loader from './components/Loader/Loader'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'

import Main from './page/main'
import PrivacyPage from './page/privacyPage'
import TermsPage from './page/termsPage'
import MyNftsPage from './page/myNftsPage'

import './App.css'

import { Toaster } from 'react-hot-toast'

import useViewportHeight from './hooks/useViewportHeight'

function App() {
  const loading = useSelector((state) => state.loadingReducer)
  useViewportHeight()

  return (
    <>
      {loading.loading && <Loader />}
      <ScrollToTop />
      <Toaster />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/privacy-policy" element={<PrivacyPage />} />
        <Route exact path="/terms-of-service" element={<TermsPage />} />
        <Route exact path="/my-nfts" element={<MyNftsPage />} />
      </Routes>
    </>
  )
}

export default App
