import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { INFT } from 'src/types/nft.interface'

export interface TraitInfo {
  name: string
  rarity: string
}

export interface nftsState {
  nft: INFT[]
}

const initialState: nftsState = {
  nft: [],
}

export const userNftsSlice = createSlice({
  name: 'userNfts',
  initialState,
  reducers: {
    setUserNfts: (state, action: PayloadAction<INFT[]>) => {
      state.nft = action.payload
    },
  },
})

export const { setUserNfts } = userNftsSlice.actions

export default userNftsSlice.reducer
